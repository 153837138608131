<template>
  <div class="user-index__wrapper">
    <template v-for="item in notifyList">
      <CCCNotificationComponent
        v-if="item.type === 'cccMsg' && handleShowCccNotice(item)"
        :key="item.type"
        :data="item"
      />
    </template>
    <div class="user-index__content">
      <!-- 主内容 -->
      <MainContent
        :expire-point-coupon-info="expirePointCouponInfo"
        :personal-center-entrance-info="personalCenterEntranceInfo"
        :dynamic-service="dynamicService"
      />
      <!-- 右侧栏 -->
      <AsideContent :to-link="toLink" />
      <!-- 未支付订单弹框提示 -->
      <component
        :is="isShowNewUnpaidDialog ? UnpaidDialogV2 : UnpaidDialog"
        :order-item="unpaidOrderDialogData"
        :configs="USER_CENTER_UNPAID_ORDER_CONFIG"
      />
    </div>
  </div>
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  provide,
  reactive,
  ref
} from 'vue'
import mitt from 'mitt'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import CCCNotificationComponent from './newComponents/header/CCCNotification.vue'
import MainContent from './newComponents/mainContent/MainContent.vue'
import AsideContent from './newComponents/aside/AsideContent.vue'
import { analysisEvent } from './analysis'
import { queryFirstPartInfoApi, querySecondPartInfoApi } from './service'

const UnpaidDialog = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "UnpaidDialog" */ './newComponents/unpaid-dialog/UnpaidDialog.vue'
  )
)
const UnpaidDialogV2 = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "UnpaidDialog" */ './newComponents/unpaid-dialog/unpaid-dialog-v2/UnpaidDialogV2.vue'
  )
)

const props = defineProps({
  context: {
    type: Object,
    default: () => ({})
  }
})

daEventCenter.addSubscriber({ modulecode: '1-22-2' })

const {
  SiteUID,
  USER_CENTER_UNPAID_ORDER_CONFIG,
  PUBLIC_CDN = '',
  IS_RW = '',
  GB_cssRight = false,
  IMG_LINK = {},
  langPath,
  RESOURCE_SDK
} = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo

const commonState = reactive({
  abtInfo: props.context.abtInfo,
  language: props.context.language,
  userInfo: {},
  userInfoReady: false,
  constantData: {
    SiteUID,
    USER_CENTER_UNPAID_ORDER_CONFIG,
    PUBLIC_CDN,
    IS_RW,
    GB_cssRight,
    IMG_LINK,
    langPath,
    RESOURCE_SDK
  },
  apolloConfig: props.context.apolloConfig,
  isRisk: true
})

const toLink = (link, id, activityParams = {}) => {
  if (commonState.isRisk) {
    window.userIndexEventCenter.emit('riskDialog', true)
    return
  }
  analysisEvent(id, activityParams)
  window.location.href = link
}
provide('commonState', commonState)
provide('toLink', toLink)
const notifyList = ref([])
const expirePointCouponInfo = ref({})
const dynamicService = ref({})
const personalCenterEntranceInfo = ref({})
// 未支付挽留弹框数据
const unpaidOrderDialogData = ref({})
const isShowNewUnpaidDialog = computed(() =>
  ['A', 'B'].includes(commonState.abtInfo.MeOrderUnpaid?.p?.PopType)
)

const getFirstPartInfo = async () => {
  const res = await queryFirstPartInfoApi()
  const data = res?.info || {}
  commonState.userInfo = Object.assign(
    props.context.userInfo,
    data.userLevelInfo,
    data.personalInfo
  )
  nextTick(() => {
    commonState.userInfoReady = true
  })
  // 黑名单、风控
  // commonState.isRisk =
  //   data.userLevelInfo?.isBlack ||
  //   ['change_pwd_blasting', 'change_pwd_leak'].includes(
  //     data.personalInfo?.riskInfo?.changePwd?.name
  //   )
  commonState.isRisk =
    commonState.userInfo.is_black_list_user === 1 ||
    commonState.userInfo.is_new_reveal === 1
  notifyList.value = data.notifyService?.notifyList || []
  expirePointCouponInfo.value = data.expirePointCouponInfo || {}
  personalCenterEntranceInfo.value = data.personalCenterEntranceInfo || {}
  dynamicService.value = data.dynamicService || {}
  // 埋点
  window.SaPageInfo.page_param.vip_level = commonState.userInfo.level
  window.sa('set', 'setPageData', SaPageInfo)
  window.sa('send', 'pageEnter', { start_time: new Date().getTime() })
  return data
}
const handleShowCccNotice = data => {
  const hideCccNotice = getLocalStorage('hideCccNotice')
  if (!hideCccNotice && data.content) {
    // 公告优化 产品新加的埋点 担心之前的埋点没有被使用了 保留两份
    daEventCenter.triggerNotice({
      daId: '1-22-2-7'
    })
    daEventCenter.triggerNotice({
      daId: '1-22-2-16'
    })
    return true
  }
  return false
}
const getSecondPartInfo = async () => {
  const res = await querySecondPartInfoApi()
  return res?.info || {}
}
const handleUnpaidDialog = (data = []) => {
  if (!data.length) return
  const newBillNos = data.map(item => item.billNo)
  // 埋点上报
  daEventCenter.triggerNotice({
    daId: '1-22-2-1009',
    extraData: {
      scenes: 'unpaidOrderMe',
      order_id_list: newBillNos.join('`')
    }
  })
  if (commonState.abtInfo.unpaidOrderMe?.p?.unpaidOrderMe != 'TypeB') return
  const cacheKey = `unpaid_dialog_cache_${SiteUID}_${commonState.userInfo.member_id}`
  const cache = getLocalStorage(cacheKey) || { billNos: [], count: 0 }
  const cacheBillNos = cache.billNos || []
  const unpaidOrder = data.find(item => !cacheBillNos.includes(item.billNo))
  // 次数限制 频率限制
  if (
    !USER_CENTER_UNPAID_ORDER_CONFIG.times ||
    cache.count >= USER_CENTER_UNPAID_ORDER_CONFIG.times ||
    !USER_CENTER_UNPAID_ORDER_CONFIG.intervel ||
    cache.time >
      Date.now() - USER_CENTER_UNPAID_ORDER_CONFIG.intervel * 60 * 1000 ||
    !unpaidOrder
  ) {
    return
  }
  cacheBillNos.push(unpaidOrder.billNo)
  unpaidOrderDialogData.value = unpaidOrder
  // 设置缓存
  setLocalStorage({
    key: cacheKey,
    value: {
      count: cache.count + 1,
      time: Date.now(),
      billNos: cacheBillNos
    }
  })
}
const initData = async () => {
  const [secRes] = await Promise.all([getSecondPartInfo(), getFirstPartInfo()])
  // 处理待支付挽留弹框数据
  handleUnpaidDialog(secRes.orderRetention?.orderList)
}
const initPv = () => {
  window.SaPageInfo = {
    page_id: 115,
    page_name: 'page_me',
    start_time: new Date().getTime(),
    page_param: {
      vip_level: null
    }
  }
}
onBeforeMount(() => {
  initData()
  initPv()
})
onMounted(() => {
  //如果是爆破黑名单用户或者泄露名单用户
  window.userIndexEventCenter = window.userIndexEventCenter || mitt()
})
</script>
<style lang="less">
.user-remind {
  background: rgba(217, 234, 255, 1);
  margin-bottom: 10px;
  padding: 20px 0 20px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .user-remind-left {
    color: #666666;
    font-size: 18px;
  }
  .content {
    font-size: 14px;
    color: rgba(34, 34, 34, 1);
    flex: 1;
    margin: 0 10px;
  }
  .user-remind-right {
    color: #999;
    font-size: 14px;
    .margin-r(16px);
    .margin-l(24px);
  }
}
.user-risk-dialog {
  .sui-dialog__wrapper {
    .sui-dialog__body {
      padding: 0;
      margin: 0;
    }
  }
}
.user-index__content {
  display: flex;
  justify-content: space-between;
}
</style>
