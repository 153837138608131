<!-- 
  单双列复用，内容pannel
 -->
<template>
  <div
    class="prime-pannel2__content"
    :class="{ 'single-content': columnNumber === 1 }"
  >
    <div class="prime-pannel2__text">
      <template v-if="mainText">
        <div v-html="mainText"> </div>
      </template>
      <template v-else>
        <template v-if="currentUserPrimeStatus == 1">
          <div
            v-if="discountPrice > 0"
            v-html="
              template(
                getSpanTxt(totalDiscountPriceWithSymbol),
                getSpanTxt(arrivalPriceWithSymbol),
                language.SHEIN_KEY_PC_27806
              )
            "
          ></div>
          <div
            v-if="discountPrice == 0 && latest30DaysPayed > 0"
            v-html="
              template(
                getSpanTxt(currentUserSavedInfo.total_saving_amount_with_symbol),
                language.SHEIN_KEY_PC_27807
              )
            "
          ></div>
          <template v-if="discountPrice == 0 && !latest30DaysPayed">
            <div
              v-if="couponValueWithSymbol.length > 0"
              v-html="
                template(
                  getSpanTxt(discountPriceWithPercent),
                  getSpanTxt(curUserCouponCount),
                  getSpanTxt(couponValueWithSymbol),
                  language.SHEIN_KEY_PC_27808
                )
              "
            ></div>
            <div
              v-else
              v-html="
                template(
                  getSpanTxt(discountPriceWithPercent),
                  getSpanTxt(curUserCouponCount),
                  getSpanTxt(couponValueWithSymbol),
                  language.SHEIN_KEY_PC_28026
                )
              "
            ></div>
          </template>
        </template>

        <template v-if="currentUserPrimeStatus == 2">
          <div
            v-if="currentUserSavedMoney > 0"
            v-html="
              template(
                getSpanTxtBigSize(currentUserSavedMoneyWithSymbol),
                language.SHEIN_KEY_PC_27010
              )
            "
          ></div>
          <template v-else>
            <div
              v-if="couponValueWithSymbol.length > 0"
              v-html="
                template(
                  getSpanTxt(discountPriceWithPercent),
                  getSpanTxt(curUserCouponCount),
                  getSpanTxt(couponValueWithSymbol),
                  language.SHEIN_KEY_PC_27819
                )
              "
            ></div>
            <div
              v-else
              v-html="
                template(
                  getSpanTxt(discountPriceWithPercent),
                  language.SHEIN_KEY_PC_28026
                )
              "
            ></div>
          </template>
        </template>

        <template v-if="currentUserPrimeStatus == 3">
          <template v-if="currentUserSavedMoney == 0 && discountPrice > 0">
            <div
              v-html="
                template(
                  getSpanTxt(totalDiscountPriceWithSymbol),
                  getSpanTxt(arrivalPriceWithSymbol),
                  language.SHEIN_KEY_PC_27813
                )
              "
            ></div>
          </template>
          <template v-if="currentUserSavedMoney == 0 && discountPrice == 0">
            <div
              v-if="probationaryMemberBuy30"
              v-html="language.SHEIN_KEY_PC_27814"
            ></div>
            <div
              v-else
              v-html="language.SHEIN_KEY_PC_27817"
            ></div>
          </template>
          <template v-if="currentUserSavedMoney > 0 && discountPrice == 0">
            <div
              v-if="probationaryMemberBuy30"
              v-html="
                template(
                  getSpanTxtBigSize(currentUserSavedMoneyWithSymbol),
                  language.SHEIN_KEY_PC_27816
                )
              "
            ></div>
            <div
              v-else
              v-html="
                template(
                  getSpanTxtBigSize(currentUserSavedMoneyWithSymbol),
                  language.SHEIN_KEY_PC_27818
                )
              "
            ></div>
          </template>
          <template v-if="currentUserSavedMoney > 0 && discountPrice > 0">
            <!-- 2023-11-22 加个判断，临期<=30天(3 -> SHEIN_KEY_PC_27815), 临期>30天(3 -> SHEIN_KEY_PC_27811) -->
            <div
              v-if="probationaryMemberBuy30"
              v-html="
                template(
                  getSpanTxtBigSize(currentUserSavedMoneyWithSymbol),
                  getSpanTxt(totalDiscountPriceWithSymbol),
                  getSpanTxt(arrivalPriceWithSymbol),
                  language.SHEIN_KEY_PC_27815
                )
              "
            ></div>
            <div
              v-else
              v-html="
                template(
                  getSpanTxtBigSize(currentUserSavedMoneyWithSymbol),
                  getSpanTxt(totalDiscountPriceWithSymbol),
                  getSpanTxt(arrivalPriceWithSymbol),
                  language.SHEIN_KEY_PC_27811
                )
              "
            ></div>
          </template>
        </template>

        <template v-if="currentUserPrimeStatus == 4">
          <template v-if="buyWhen == 4">
            <template v-if="discountPrice > 0">
              <div
                v-html="
                  template(
                    getSpanTxt(totalDiscountPriceWithSymbol),
                    getSpanTxt(arrivalPriceWithSymbol),
                    language.SHEIN_KEY_PC_27806
                  )
                "
              ></div>
            </template>
            <template v-else-if="discountPrice == 0 && latest30DaysPayed > 0">
              <div
                v-html="
                  template(
                    getSpanTxt(currentUserSavedInfo.total_saving_amount_with_symbol),
                    language.SHEIN_KEY_PC_27807
                  )
                "
              ></div>
            </template>
            <template v-else>
              <div
                v-if="couponValueWithSymbol.length > 0"
                v-html="
                  template(
                    getSpanTxt(discountPriceWithPercent),
                    getSpanTxt(curUserCouponCount),
                    getSpanTxt(couponValueWithSymbol),
                    language.SHEIN_KEY_PC_27808
                  )
                "
              ></div>
              <div 
                v-else
                v-html="
                  template(
                    getSpanTxt(discountPriceWithPercent),
                    getSpanTxt(curUserCouponCount),
                    getSpanTxt(couponValueWithSymbol),
                    language.SHEIN_KEY_PC_28026
                  )
                "
              >
              </div>
            </template>
          </template>
          
          <template v-else>
            <div
              v-if="historySavedMoney == 0 && discountPrice > 0"
              v-html="
                template(
                  getSpanTxt(totalDiscountPriceWithSymbol),
                  getSpanTxt(arrivalPriceWithSymbol),
                  language.SHEIN_KEY_PC_27809
                )
              "
            ></div>
            <template v-if="historySavedMoney == 0 && discountPrice == 0">
              <div
                v-if="couponValueWithSymbol.length > 0"
                v-html="
                  template(
                    getSpanTxt(discountPriceWithPercent),
                    getSpanTxt(curUserCouponCount),
                    getSpanTxt(couponValueWithSymbol),
                    language.SHEIN_KEY_PC_27810
                  )
                "
              ></div>
              <div
                v-else
                v-html="
                  template(
                    getSpanTxt(discountPriceWithPercent),
                    language.SHEIN_KEY_PC_28025
                  )
                "
              ></div>
            </template>
            <template v-if="historySavedMoney > 0 && discountPrice == 0">
              <div
                v-if="hasUnpaidOrder"
              >
                <div
                  v-if="couponValueWithSymbol.length > 0"
                  v-html="
                    template(
                      getSpanTxt(discountPriceWithPercent),
                      getSpanTxt(curUserCouponCount),
                      getSpanTxt(couponValueWithSymbol),
                      language.SHEIN_KEY_PC_27808
                    )
                  "
                ></div>
                <div
                  v-else
                  v-html="
                    template(
                      getSpanTxt(discountPriceWithPercent),
                      getSpanTxt(curUserCouponCount),
                      getSpanTxt(couponValueWithSymbol),
                      language.SHEIN_KEY_PC_28026
                    )
                  "
                ></div>
              </div>
              <div
                v-else-if="couponValueWithSymbol.length > 0"
                v-html="
                  template(
                    getSpanTxtBigSize(historySavedMoneyWithSymbol),
                    getSpanTxt(discountPriceWithPercent),
                    getSpanTxt(curUserCouponCount),
                    getSpanTxt(couponValueWithSymbol),
                    language.SHEIN_KEY_PC_27812
                  )
                "
              ></div>
              <div
                v-else
                v-html="
                  template(
                    getSpanTxtBigSize(historySavedMoneyWithSymbol),
                    getSpanTxt(curDiscountValueText),
                    language.SHEIN_KEY_PC_28024
                  )
                "
              ></div>
            </template>
            <template v-if="historySavedMoney > 0 && discountPrice > 0">
              <div
                v-html="
                  template(
                    getSpanTxtBigSize(historySavedMoneyWithSymbol),
                    getSpanTxt(totalDiscountPriceWithSymbol),
                    getSpanTxt(arrivalPriceWithSymbol),
                    language.SHEIN_KEY_PC_27811
                  )
                "
              ></div>
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

export default {
  name: 'PrimePannelContent',
  props: {
    /**
     * @param {Number} currentUserPrimeStatus 1. 未购买 / 退订中 2. 生效中-非临期 3. 生效中-临期 4. 逾期/ 已退订
     * <---- 1 ----->
     * @param {Number} discountPrice 是否有购买优惠数额
     * @param {String} reducePriceWithSymbol 会费折扣金额
     * @param {String} specialPriceWithSymbol 优惠价
     * @param {Number} latest30DaysPayed 最近30天支付数额
     * @param {Number} currentUserSavedInfo 当前用户已省信息
     * @param {String} couponValueWithSymbol 免邮列表
     * @param {String} discountPriceWithPercent 购买优惠
     * @param {Number} curUserCouponCount 免邮券张数
     *  <------ 2 ------>
     * @param {Number} currentUserSavedMoney 使用中的会员的省额
     * @param {String} currentUserSavedMoneyWithSymbol 使用中的会员的省额带币种
     * <------ 3 ------>
     * @param {Boolean} probationaryMemberBuy30 临期天数<= 30天
     * <------ 4 ------>
     * @param {Number} historySavedMoney 历史省额: 已过期的会员就叫历史省额
     * @param {String} historySavedMoneyWithSymbol 历史省额: 已过期的会员就叫历史省额 带币种
     *
     * @param {Function} getSpanTxt
     * @param {Function} getSpanTxtBigSize
     *
     * @param {Object} language
     *
     * @param {Number} columnNumber 单双列
     */
    currentUserPrimeStatus: {
      type: Number,
      default: 1,
    },
    discountPrice: {
      type: Number,
      default: 0,
    },
    reducePriceWithSymbol: {
      type: String,
      default: '',
    },
    totalDiscountPriceWithSymbol: {
      type: String,
      default: '',
    },
    specialPriceWithSymbol: {
      type: String,
      default: '',
    },
    arrivalPriceWithSymbol: {
      type: String,
      default: ''
    },
    latest30DaysPayed: {
      type: Number,
      default: 0,
    },
    currentUserSavedInfo: {
      type: Object,
      default: () => {},
    },
    couponValueWithSymbol: {
      type: String,
      default: () => '',
    },
    discountPriceWithPercent: {
      type: String,
      default: '',
    },
    curUserCouponCount: {
      type: Number,
      default: 0,
    },
    currentUserSavedMoney: {
      type: Number,
      default: 0,
    },
    currentUserSavedMoneyWithSymbol: {
      type: String,
      default: '',
    },
    probationaryMemberBuy30: {
      type: Boolean,
      default: false,
    },
    historySavedMoney: {
      type: Number,
      default: 1,
    },
    historySavedMoneyWithSymbol: {
      type: String,
      default: '',
    },
    getSpanTxtBigSize: {
      type: Function,
      default: () => {},
    },
    getSpanTxt: {
      type: Function,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    columnNumber: {
      type: Number,
      default: 0,
    },
    buyWhen: {
      type: Number,
      default: 0,
    },
    curDiscountValueText: {
      type: String,
      default: '',
    },
    hasUnpaidOrder: {
      type: String,
      default: '',
    },
    mainText: {
      type: String,
      default: '',
    },
  },
  methods: {
    template,
  },
}
</script>

<style lang="less" scoped>
.prime-pannel2 {
  &__content {
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    min-height: 72px;
  }
  &__text {
    color: #873c00;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    span {
      color: #fa6338;
      font-weight: bold;
    }
    .amount {
      color: #873c00;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.single-content {
  padding: 10px;
  box-sizing: border-box;
  min-height: 54px;
}
</style>

