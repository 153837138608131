<template>
  <div class="tips-list">
    <div
      class="tips-arrow"
    >
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 1 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 2 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 3 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 4 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
    </div>
    <s-alert
      key="coupon"
      type="warning"
      :closeable="false"
      class="preference-remind tips-remind-ctn"
      @click="onClick"
    >
      <div tabindex="0">
        {{ tipsContent }}
      </div>
      <template #operation>
        <Icon
          name="sui_icon_closed_14px_1"
          color="#999"
          size="12px"
          class="icon-jumpable"
          @click.stop="onClose"
        />
      </template>
    </s-alert>
  </div>
</template>

<script setup name="AlertTips">
import { Icon } from '@shein-aidc/icon-vue3'
defineProps({
  arrowPosition: {
    type: Number,
    default: 0,
  },
  tipsContent: {
    type: String,
    default: '',
  },
})

const emits = defineEmits([
  'on-close',
  'on-click',
])
const onClose = () => emits('on-close')
const onClick = () => emits('on-click')
</script>

<style lang="less" scoped>
.tips-list {
  .tips-arrow {
    display: flex;
    .arrow {
      flex: 1;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .arrow-icon {
      border: 10px solid transparent;
      border-bottom-color: #fff7e7;
      width: 10px;
      height: 10px;
      display: inline-block;
    }
  }
}
.preference-remind {
  .padding-r(0);
  margin-bottom: 10px;
  &.tips-remind-ctn {
    margin-bottom: 0;
    & when (@IS_RW) {
      border-radius: 4px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .icon-jumpable {
    .margin-r(16px);
    .margin-l(24px);
    color: #999;
    cursor: pointer;
  }
}
</style>
