<template>
  <div
    v-expose="{
      id: '1-22-2-34'
    }"
    class="expire-game-money__tips-wrapper"
    @click.stop="onTipsClick"
  >
    <i class="expire-game-money__arrow"></i>
    <div
      class="expire-game-money__tips"
      v-html="tipsContent"
    ></div>
    <i
      class="expire-game-money__close-icon"
      @click.stop="onTipsClose"
    ></i>
  </div>
</template>

<script setup name="ExpireGameMoneyTips">
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { ExpireTipsClickStatus } from 'public/src/pages/user_index/constants'
import { setLocalStorage } from 'public/src/pages/user_index/utils/storage'
import {
  getExpireGameMoneyCache,
  getExpireGameMoneyCacheKey,
} from './user-assets-bottom'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  userInfo: {
    type: Object,
    default: () => ({}),
  },
  expireGameMoney: {
    type: Object,
    default: null,
  },
})

const emits = defineEmits([
  'hide-module',
])

const tipsContent = computed(() => template(
  `<span style="display: inline-block;color: #FA6338;font-weight: 700;font-family: 'SF-UI-Text-Bold';direction: ltr;">${
    props.expireGameMoney?.expireAmountWithSymbol
  }</span>`,
  props.language?.SHEIN_KEY_PC_31651 || 'You have {0} that is about to expire'))

const onTipsClick = () => {
  setTimeout(() => {
    const link = '/user/wallet'
    window.location.href = link
  }, 300)
  emits('hide-module')
  const cacheKey = getExpireGameMoneyCacheKey(props.userInfo)
  setLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.NO_LONGER_SHOW,
  })
  daEventCenter.triggerNotice({
    daId: '1-22-2-36',
  })
}

const onTipsClose = () => {
  emits('hide-module')
  updateTipsCloseTimes()
  daEventCenter.triggerNotice({
    daId: '1-22-2-35',
  })
}

const updateTipsCloseTimes = () => {
  const { userInfo } = props
  const expireTipCache = getExpireGameMoneyCache(userInfo)
  const after24Hours = new Date().getTime() + 24 * 3600 * 1000
  const cacheKey = getExpireGameMoneyCacheKey(userInfo)
  setLocalStorage(cacheKey, {
    ...expireTipCache,
    closeTimes: expireTipCache.closeTimes + 1,
    displayAfterTime: after24Hours,
  })
}
</script>

<style lang="less" scoped>
.expire-game-money {
  &__tips-wrapper {
    position: relative;
    margin-top: 8px;
    padding: 10px 32px 10px 8px;
    background-color: #FFF1D5;
  }
  &__arrow {
    position: absolute;
    border-width: 5px 6px;
    border-style: solid;
    border-color: transparent transparent #FFF1D5 transparent;
    top: -10px;
    left: 62.5% /* rtl:unset */;
    right: unset /* rtl:62.5% */;
    transform: translateX(-50%);
  }
  &__tips {
    font-size: 12px;
    line-height: 1.1666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 28px;
    font-family: 'SF-UI-Text-Regular';
  }
  &__close-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    right: 8px /* rtl:unset */;
    left: unset /* rtl:8px */;
    background: url(https://img.ltwebstatic.com/images3_ccc/2024/07/10/78/17206157549e524e376ac378f369298061b4dd62b4.png) 50% 50% / contain no-repeat;
  }
}
</style>

