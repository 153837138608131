<template>
  <!-- 待评论订单 -->
  <div
    v-if="curUnReviewOrder"
    class="unreview-order fsp-element"
    @click="goToReviewDetail(curUnReviewOrder)"
  >
    <img
      class="unreview-order__logo"
      :src="`${PUBLIC_CDN}/she_dist/images/review_center/union-53f194fd65.png`"
    />
    <div class="unreview-order__info">
      <div class="unreview-order__content">
        <img
          class="unreview-order__icon"
          :src="curUnReviewOrder?.['goodImage']"
          alt=""
        />
        <div
          class="unreview-order__text"
          v-html="curUnReviewOrder?.['upTips']"
        ></div>
      </div>
      <s-button :type="['H28PX', 'primary']">
        {{ language.SHEIN_KEY_PC_27373 }}
      </s-button>
    </div>
  </div>
</template>
<script>
import { daEventCenter } from 'public/src/services/eventCenter'
const { GB_cssRight = false, langPath = '' } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'UnReviewList',
  props: {
    unReviewList: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      GB_cssRight,
      PUBLIC_CDN: '',
      langPath
    }
  },
  computed: {
    curUnReviewOrder() {
      return this.unReviewList[0]
    },
    buriedObject() {
      return this.curUnReviewOrder?.buriedObject
    },
    exposeBuriedObject() {
      return this.buriedObject?.find(
        n => n.buriedKey === 'order_floor' && n.buriedType === 1
      )?.defaultParams
    },
    clickBuriedObject() {
      return this.buriedObject?.find(
        n => n.buriedKey === 'order_floor' && n.buriedType === 2
      )?.defaultParams
    }
  },
  watch: {
    exposeBuriedObject: {
      handler(val) {
        if (!val) return
        daEventCenter.triggerNotice({
          daId: '1-22-2-1003',
          extraData: val
        })
      }
    },
    curUnReviewOrder: {
      handler(val) {
        if (!val) return
        daEventCenter.triggerNotice({
          daId: '1-22-2-1005',
          extraData: {
            scenes:
              this.exposeBuriedObject?.is_first_review == 1
                ? 'firstReview'
                : 'review',
            order_id_list: this.unReviewList.map(n => n.billNo).join('`')
          }
        })
      }
    }
  },
  methods: {
    goToReviewDetail(item) {
      window.location.href = `${this.langPath}/user/orders/order_review?billno=${item.billNo}&review_page_location=individual`
      daEventCenter.triggerNotice({
        daId: '1-22-2-1004',
        extraData: this.clickBuriedObject
      })
    }
  }
}
</script>
<style lang="less" scoped>
.unreview-order {
  position: relative;
  display: flex;
  margin: 0px 10px 20px;
  padding: 6px 12px 6px 6px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--sui-color-micro-emphasis-bg, #fff8eb);
  cursor: pointer;
  &__logo {
    position: absolute;
    top: -12px;
    right: 160px;
    width: 20px;
    height: 12px;
  }
  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 36px;
    height: 36px;
    margin-right: 6px;
  }
  &__text {
    color: #000;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /deep/ .strong-orange {
      color: #fa6338;
    }
    /deep/ span {
      font-weight: bold;
    }
  }
}
</style>
