<template>
  <li class="list-item">
    <div class="list-h">
      {{ handleDate(order.addTime) }}
    </div>
    <div class="list-c">
      <div class="col item-pic col-1">
        <div
          v-if="order.orderGoodsList?.length > 1"
          :ref="`button-prev_${index}`"
          class="item-pic__button-prev"
        >
          <Icon
            :is-rotate="GB_cssRight"
            name="sui_icon_left_48px_1"
            size="12px"
          />
        </div>
        <swiper-container
          :ref="`cccSwiperInstance_${index}`"
          class="item-pic__swiper-container"
          init="false"
          destroy-on-disconnected="false"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(goodsItem, i) in order.orderGoodsList"
            :key="`orderGoodsList-${i}`"
            class="item-pic__swiper-slide"
          >
            <img
              :class="{lazyload: index > 1}"
              :src="index > 1 ? commonInfo?.IMG_LINK?.LAZY_IMG : cutImg(goodsItem?.product?.goods_img)"
              :data-src="cutImg(goodsItem?.product?.goods_img)"
            />
          </swiper-slide>
        </swiper-container>
        <div
          v-if="order.orderGoodsList?.length > 1"
          :ref="`button-next_${index}`"
          class="item-pic__button-next"
        >
          <Icon
            :is-rotate="GB_cssRight"
            name="sui_icon_right_48px_1"
            size="12px"
          />
        </div>
      </div>
      <div class="col col-2 align-left">
        {{ calculaNumber(order.orderGoodsSum) }}
      </div>
      <div class="col col-3 align-left">
        <div
          :class="{
            'pay-price__value': true,
            'pay-price__value-new': newUnpaidOrder && discountPrice
          }"
        >
          {{ orderPrice }}
        </div>
        <div
          v-if="newUnpaidOrder && discountPrice"
          class="pay-price__discount"
          v-html="discountPrice"
        ></div>
      </div>
      <div class="col col-4 align-left">
        <div class="order-status-wrap">
          <!-- 订单状态 -->
          <span
            class="order-status"
            :data-status="order.orderStatus"
          >
            {{ orderStatus.status[order.orderStatus] }}
          </span>
          <div
            v-if="
              order.orderStatus != 11 &&
                order.orderStatus != 17 &&
                order.orderStatus != 19 &&
                order.orderStatus != 16
            "
            class="orderItem__tip-icon-spec"
            :class="{ 'c-icon-tip-spec-ar': GB_cssRight }"
          >
            <span
              v-if="!IS_RW && order.orderStatus == 5"
              class="deliver-bottom"
              @mouseenter.stop="
                showConfirmDeliverTipsEvt(
                  order,
                  orderStatus.tips[order.orderStatus]
                )
              "
              @mouseout.stop="confirmDeliverTipsBool = false"
            >
              <Icon
                name="sui_icon_doubt_14px_1"
                size="14px"
                color="#222"
              />
            </span>
            <span
              v-else-if="!(IS_RW && order.orderStatus == 5)"
              class="with-arrow-bottom"
            >
              <Icon
                name="sui_icon_doubt_14px_1"
                size="14px"
                color="#222"
              />
            </span>
            <em
              v-if="order.orderStatus == 2 && order.expired_type"
              class="tip-bottom"
            >
              <!-- 过期订单多种过期原因 -->
              {{
                htmlDecode({ text: orderStatus.expired[order.expired_type] })
              }}
            </em>
            <!-- 发货或完成显示积分 -->
            <em
              v-else-if="
                order.orderStatus == 7 && order.confirmDeliveryBonusPoints > 0
              "
              class="tip-bottom"
              v-html="
                htmlDecode({
                  text: orderStatus.tips[order.orderStatus].replace(
                    '[num]',
                    order.confirmDeliveryBonusPoints
                  )
                })
              "
            ></em>
            <template v-else-if="order.orderStatus == 5">
              <div
                v-if="confirmDeliverTipsBool"
                class="show_confirmDeliver_tips"
                v-html="confirmDeliveryText"
              ></div>
            </template>
            <em
              v-else-if="orderStatus.tips[order.orderStatus]"
              v-show="orderStatus.tips[order.orderStatus]"
              class="tip-bottom"
              v-html="htmlDecode({ text: orderStatus.tips[order.orderStatus] })"
            ></em>
          </div>
          <div
            v-if="
              order.orderStatus == 16 &&
                orderStatus.pending[order.payment_method.toLowerCase()]
            "
            class="c-icon-tip-spec__orderItem"
            :class="{ 'c-icon-tip-spec-ar': GB_cssRight }"
          >
            <span class="with-arrow-bottom">
              <Icon
                name="sui_icon_doubt_14px_1"
                size="14px"
                color="#222"
              />
            </span>
            <!-- 发货或完成显示积分 -->
            <em
              class="tip-bottom"
              v-html="orderStatus.pending[order.payment_method.toLowerCase()]"
            ></em>
          </div>
        </div>
      </div>
      <div class="col col-5">
        <template v-if="newUnpaidOrder && order.isReadOnly != 1">
          <PayBtn
            size="small"
            :pay-btn-html="language.SHEIN_KEY_PC_27590"
            :free-shipping-text="order.freeShippingTempt?.text"
            :end-time="endInSeconds"
            :in-floor="true"
            :order-item="order"
          />
        </template>
        <div
          v-if="order.isReadOnly != 1"
          class="detail-btn"
          @click="handleOrderDetailClick"
        >
          {{ language.SHEIN_KEY_PC_15021 }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
import { nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { getOrderDoublePoints } from '../../order_list/utils'
import { analysisEvent } from '../analysis'
import { daEventCenter } from 'public/src/services/eventCenter'
import orderLogic from 'public/src/pages/common/orders/orderLogic'
import { ORDER_DATE } from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import PayBtn from './unpaid_dialog_v2/PayBtn.vue'

typeof window !== 'undefined' && register()
const { GB_cssRight, PUBLIC_CDN = '' } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

import {
  htmlDecode,
  transformImg as _transformImg,
  timeTransformer,
  template
} from '@shein/common-function'

export default {
  name: 'OrderItem',
  inject: ['commonInfo', 'abtInfo', 'cutImg'],
  components: {
    Icon,
    PayBtn
  },
  props: {
    order: {
      type: Object
    },
    index: {
      type: Number
    },
    language: {
      type: Object
    },
    showPreferentialFloor: {
      type: Boolean
    }
  },
  data() {
    return {
      IS_RW: this.commonInfo.IS_RW,
      confirmDeliverTipsBool: false,
      confirmDeliveryText: '',
      GB_cssRight
    }
  },
  computed: {
    // 倒计时
    endInSeconds() {
      const { order_expire_time } = this.order || {}
      const endTime = Number(order_expire_time || 0)
      const nowTime = Date.parse(new Date()) / 1000
      return endTime > nowTime ? endTime - nowTime : 0
    },
    discountPrice() {
      const discount = +this.order.total_discount_new?.amount
        ? this.order.total_discount_new?.amountWithSymbol
        : ''
      return discount
        ? template(
            `<span class="pay-price__discount-value">${discount}</span>`,
            this.language.SHEIN_KEY_PC_30926
        )
        : ''
    },
    // 新版未支付订单
    newUnpaidOrder() {
      return (
        this.showPreferentialFloor && [12, 13].includes(this.order.orderStatus)
      )
    },
    orderPrice() {
      return this.order.currencyTotalPrice
        ? this.order.currencyTotalPrice.amountWithSymbol
        : this.order.totalPrice
          ? this.order.totalPrice.amountWithSymbol
          : ''
    },
    orderStatus() {
      const language = this.language
      return {
        status: orderLogic.orderStatus(language),
        tips: {
          0: language.SHEIN_KEY_PC_15183,
          1: language.SHEIN_KEY_PC_15186,
          2: language.SHEIN_KEY_PC_15101,
          3: language.SHEIN_KEY_PC_15189,
          4: language.SHEIN_KEY_PC_16982,
          5: language.SHEIN_KEY_PC_17211,
          6: language.SHEIN_KEY_PC_15284,
          7: language.SHEIN_KEY_PC_15192,
          8: language.SHEIN_KEY_PC_15306,
          9: language.SHEIN_KEY_PC_15308,
          10: language.SHEIN_KEY_PC_15184,
          11: language.SHEIN_KEY_PC_15187,
          12: language.SHEIN_KEY_PC_15183,
          13: language.SHEIN_KEY_PC_15283,
          14: language.SHEIN_KEY_PC_15326,
          15: language.SHEIN_KEY_PC_15326,
          16: '',
          17: '',
          18: language.SHEIN_KEY_PC_15184,
          19: ''
        },
        pending: {
          paypal:
            language.SHEIN_KEY_PC_15496 + '' + language.SHEIN_KEY_PC_15497,
          'paypal-paypal':
            language.SHEIN_KEY_PC_15496 + '' + language.SHEIN_KEY_PC_15497
        },
        expired: {
          timeout: language.SHEIN_KEY_PC_15100,
          timeout_givesaway: language.SHEIN_KEY_PC_15101,
          out_of_stock: language.SHEIN_KEY_PC_15102,
          timeout_verify: language.SHEIN_KEY_PC_15285
        }
      }
    }
  },
  mounted() {
    this.orderSwiper()

    // 曝光埋点上报
    daEventCenter.triggerNotice({
      daId: '1-22-2-1003',
      extraData: this.getAnaData()
    })
  },
  methods: {
    handleOrderDetailClick() {
      daEventCenter.triggerNotice({
        daId: '1-22-2-1004',
        extraData: this.getAnaData()
      })
      this.toLink(
        `/user/orders/detail/${this.order.billno}${
          this.order.isOldOrder ? '?orderType=oldorder' : ''
        }`,
        'orderDetail'
      )
    },
    getAnaData() {
      return {
        order_id: this.order.billno,
        floor_type: this.showPreferentialFloor ? 'new_floor' : 'unpaid',
        is_first_review: '-',
        discount_status: this.showPreferentialFloor
          ? +this.order.total_discount_new?.amount
            ? 1
            : 0
          : '-'
      }
    },
    htmlDecode: ({ text, isHtml = true }) => htmlDecode({ text, isHtml }),
    transformImg: _transformImg,
    handleDate(date) {
      if (!date) return date
      const time = date * 1000
      const { SiteUID, dateLangMap } = this.commonInfo
      const responseLocals = { SiteUID, dateLangMap }
      return `${timeTransformer(
        { time, sDateMap: ORDER_DATE },
        responseLocals
      )} ${timeTransformer({ time, defaultCode: 'G-1' }, responseLocals)}`
    },
    toLink(link, id) {
      analysisEvent(id)
      window.location.href = link
    },
    calculaNumber(goods) {
      var num = goods - 0
      if (num == 1) {
        num = num + ' ' + this.language.SHEIN_KEY_PC_15447
      } else {
        num = num + ' ' + this.language.SHEIN_KEY_PC_15448
      }
      return num
    },
    orderSwiper() {
      nextTick(() => {
        const swiperEl = this.$refs[`cccSwiperInstance_${this.index}`]
        if (!swiperEl) return

        const options = {
          injectStylesUrls: [
            `${PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`
          ],
          modules: [Navigation],
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 0,
          observer: true
        }

        if (this.order.orderGoodsList?.length > 1) {
          options.navigation = {
            prevEl: this.$refs[`button-prev_${this.index}`],
            nextEl: this.$refs[`button-next_${this.index}`]
          }
        }

        Object.assign(swiperEl, options)
        swiperEl.initialize()
      })
    },
    async showConfirmDeliverTipsEvt(order, text) {
      if (!text) return text
      let { confirmDeliveryBonusPoints: num = 0, billno } = order
      let isDoublePoints = false
      let { doublePoints = 0 } = await getOrderDoublePoints(billno)
      if (doublePoints) {
        num =
          parseInt(num + doublePoints) + '(' + num + '+' + doublePoints + ')'
        isDoublePoints = true
      } else {
        isDoublePoints = false
      }
      this.confirmDeliveryText = isDoublePoints
        ? text.replace('[num]', `<span class="red">${num}</span>`)
        : text.replace('[num]', num)
      this.confirmDeliverTipsBool = true
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern */
.c-icon-tip-spec-ar > a.with-arrow-bottom:after,
.c-icon-tip-spec-ar > span.with-arrow-bottom:after {
  transform: translateX(-50%) rotate(-45deg);
}
.c-icon-tip-spec-ar > a.with-arrow-bottom:after {
  top: 18px;
}
.c-icon-tip-spec-ar > span.with-arrow-bottom:after {
  top: 23px;
}
.item-pic__button-prev, .item-pic__button-next {
  cursor: pointer;
}
.pay-price {
  &__value {
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
  }

  &__value-new {
    color: #fa6338;
  }

  &__discount {
    display: flex;
    align-items: center;
    margin-top: 2.5px;
    font-size: 12px;
    line-height: 14px;
    color: var(---sui_color_gray_light1, #959595);
  }
  
  &__discount-value {
    margin-right: unset /* rtl: 2px */;
    margin-left: 2px /* rtl: unset */;
    color: #fa6338;
    font-weight: 700;
  } 
}
.orderItem__tip-icon-spec {
	position: relative;
	display: inline-block;
	color: #999;
	padding: 5px 8px;
	&:hover {
		color: #666;
		cursor: pointer;
		> em {
			display: block;
		}
		>span, > a {
			&.with-arrow-left,&.with-arrow-right,&.with-arrow-top,&.with-arrow-bottom {
				&:after {
					display: block;
				}
				&.remove-arrow:after {
					display: none;
				}
			}
			&.over-time {
				&:after {
					display: none;
				}
			}
		}
	}
	i{
		vertical-align: middle;
		font-size: 16px;
		margin-top: -3px;
    display: inline-block;
		color: @sui_color_gray_dark1;
	}
	> a {
		color: #666;
		text-decoration: underline;
		margin-top: 1px;
    display: inline-block;
	}
	> span, > a {
		position: relative;
		&.with-arrow-bottom {
			&:after {
				position: absolute;
				left: 50%;
				z-index: @zindex-hover-tip;
				top: 18px;
				width: 12px;
				height: 12px;
				display: none;
				content: '';
				background: #fff;
				.border-l(1px,#ccc);
				border-top: 1px solid #ccc;
				transform: translateX(-50%) rotate(45deg);
			}
			&__button {
				&:after {
					top: 32px;
				}
			}
		}
		&.with-arrow-top {
			&:after {
				position: absolute;
				left: 50%;
				z-index: @zindex-hover-tip;
				bottom: 18px;
				width: 12px;
				height: 12px;
				display: none;
				content: '';
				background: #fff;
				.border-r(1px,#ccc);
				border-bottom: 1px solid #ccc;
				transform: translateX(-50%) rotate(45deg);
			}
		}
		&.with-arrow-left {
			&:after {
				position: absolute;
				top: 50%;
				.left(-16px);
				z-index: @zindex-hover-tip;
				width: 12px;
				height: 12px;
				display: none;
				content: '';
				background: #fff;
				.border-r(1px,#ccc);
				border-top: 1px solid #ccc;
				transform: translateY(-50%) rotate(45deg);
			}
		}
		&.with-arrow-right {
			&:after {
				position: absolute;
				top: 50%;
				.right(-16px);
				z-index: @zindex-hover-tip;
				width: 12px;
				height: 12px;
				display: none;
				content: '';
				background: #fff;
				.border-l(1px,#ccc);
				border-bottom: 1px solid #ccc;
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
	> em {
		&.auto-active {
			width: auto;
		}
		display: none;
		position: absolute;
    background: #fff;
		z-index: @zindex-hover;
		width: 300px;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 6px;
		&.tip-bottom {
			top: 29px;
			left: 50%;
      transform: translateX(-50%);
		}
		&.tip-bottom-left {
			top: 29px;
			.left(-10px);
		}
		&.tip-bottom-right {
			top: 29px;
			.right(-10px);
			&__button {
				top: 43px;
			}
		}
		&.tip-top {
			bottom: 29px;
			left: 50%;
      transform: translateX(-50%);
		}
		&.tip-top-left {
			bottom: 29px;
			.left(-10px);
		}
		&.tip-top-right {
			bottom: 29px;
			.right(-10px);
		}
		&.tip-left {
			.right(34px);
			top: 50%;
      transform: translateY(-50%);
		}
		&.tip-right {
			.left(34px);
			top: 50%;
      transform: translateY(-50%);
		}
		&.tip-inner-left {
			right: 100%;
			top: 50%;
      transform: translateY(-50%);
			.margin-r(1px);
		}
		&.tip-inner-right {
			left: 100%;
			top: 50%;
      transform: translateY(-50%);
			.margin-l(1px);
		}
	}
}
</style>
