<template>
  <li class="order-item__wrapper">
    <div class="order-item__date">
      {{ handleDate(order.addTime) }}
    </div>
    <div class="order-item__content">
      <div class="order-item__cell order-item__cell-img">
        <div
          v-if="order.orderGoodsList?.length > 1"
          :ref="`swiperButtonPrev_${index}`"
          class="order-item__swiper-button-prev"
        >
          <Icon
            :is-rotate="GB_cssRight"
            name="sui_icon_left_48px_1"
            size="12px"
          />
        </div>
        <swiper-container
          :ref="`swiperRef_${index}`"
          class="order-item__swiper-container"
          init="false"
          destroy-on-disconnected="false"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(goodsItem, i) in order.orderGoodsList"
            :key="`orderGoodsList-${i}`"
            class="order-item__swiper-slide"
          >
            <img
              :class="{ lazyload: index > 1 }"
              :src="
                index > 1
                  ? commonInfo?.IMG_LINK?.LAZY_IMG
                  : goodsItem?.product?.goods_img
              "
              :data-src="goodsItem?.product?.goods_img"
            />
          </swiper-slide>
        </swiper-container>
        <div
          v-if="order.orderGoodsList?.length > 1"
          :ref="`swiperButtonNext_${index}`"
          class="order-item__swiper-button-next"
        >
          <Icon
            :is-rotate="GB_cssRight"
            name="sui_icon_right_48px_1"
            size="12px"
          />
        </div>
      </div>
      <div class="order-item__cell align-left">
        {{ calculaNumber(order.orderGoodsSum) }}
      </div>
      <div class="order-item__cell align-left">
        <div
          :class="{
            'pay-price__value': true,
            'pay-price__value-new': newUnpaidOrder && discountPrice
          }"
        >
          {{ orderPrice }}
        </div>
        <div
          v-if="newUnpaidOrder && discountPrice"
          class="pay-price__discount"
          v-html="discountPrice"
        ></div>
      </div>
      <div class="order-item__cell align-left">
        <div class="order-status-wrap">
          <!-- 订单状态 -->
          <span
            class="order-status"
            :data-status="order.orderStatus"
          >
            {{ orderStatus.status[order.orderStatus] }}
          </span>
          <div
            v-if="
              order.orderStatus != 11 &&
                order.orderStatus != 17 &&
                order.orderStatus != 19 &&
                order.orderStatus != 16
            "
            class="order-status__icon c-icon-tip-spec"
            :class="{ 'order-status__icon-ar': GB_cssRight }"
          >
            <span
              v-if="!IS_RW"
              class="with-arrow-bottom"
            >
              <Icon
                name="sui_icon_doubt_14px_1"
                size="14px"
                color="#222"
              />
            </span>
            <em
              v-if="order.orderStatus == 2 && order.expired_type"
              class="tip-bottom"
            >
              <!-- 过期订单多种过期原因 -->
              {{
                htmlDecode({ text: orderStatus.expired[order.expired_type] })
              }}
            </em>
            <!-- 发货或完成显示积分 -->
            <em
              v-else-if="
                order.orderStatus == 7 && order.confirmDeliveryBonusPoints > 0
              "
              class="tip-bottom"
              v-html="
                htmlDecode({
                  text: orderStatus.tips[order.orderStatus].replace(
                    '[num]',
                    order.confirmDeliveryBonusPoints
                  )
                })
              "
            ></em>
            <em 
              v-else-if="order.orderStatus == 5 && confirmDeliverTipsBool"
              class="tip-bottom"
              v-html="confirmDeliveryText"
            ></em>
            <em
              v-else-if="orderStatus.tips[order.orderStatus]"
              v-show="orderStatus.tips[order.orderStatus]"
              class="tip-bottom"
              v-html="htmlDecode({ text: orderStatus.tips[order.orderStatus] })"
            ></em>
          </div>
          <div
            v-if="
              order.orderStatus == 16 &&
                orderStatus.pending[order.payment_method.toLowerCase()]
            "
            class="order-status__icon c-icon-tip-spec"
            :class="{ 'order-status__icon-ar': GB_cssRight }"
          >
            <span class="with-arrow-bottom">
              <Icon
                name="sui_icon_doubt_14px_1"
                size="14px"
                color="#222"
              />
            </span>
            <!-- 发货或完成显示积分 -->
            <em
              class="tip-bottom"
              v-html="orderStatus.pending[order.payment_method.toLowerCase()]"
            ></em>
          </div>
        </div>
      </div>
      <div class="order-item__cell">
        <template v-if="newUnpaidOrder && order.isReadOnly != 1">
          <PayBtn
            size="small"
            :pay-btn-html="language.SHEIN_KEY_PC_27590"
            :free-shipping-text="order.freeShippingLabel"
            :end-time="order.expireCountdown"
            :in-floor="true"
            :order-item="order"
          />
        </template>
        <div
          v-if="order.isReadOnly != 1"
          class="order-item__cell-btn"
          @click="handleOrderDetailClick"
        >
          {{ language.SHEIN_KEY_PC_15021 }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
import { nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { getOrderDoublePoints } from '../../../order_list/utils'
import { analysisEvent } from '../../analysis'
import { daEventCenter } from 'public/src/services/eventCenter'
import orderLogic from 'public/src/pages/common/orders/orderLogic'
import { ORDER_DATE } from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import PayBtn from '../unpaid-dialog/unpaid-dialog-v2/PayBtn.vue'

typeof window !== 'undefined' && register()
const { GB_cssRight, PUBLIC_CDN = '' } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

import {
  htmlDecode,
  transformImg as _transformImg,
  timeTransformer,
  template
} from '@shein/common-function'

export default {
  name: 'OrderItem',
  components: {
    Icon,
    PayBtn
  },
  props: {
    order: {
      type: Object
    },
    index: {
      type: Number
    },
    language: {
      type: Object
    },
    showPreferentialFloor: {
      type: Boolean
    }
  },
  data() {
    return {
      commonInfo: typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {},
      IS_RW: this.commonInfo?.IS_RW,
      confirmDeliverTipsBool: false,
      confirmDeliveryText: '',
      GB_cssRight
    }
  },
  computed: {
    discountPrice() {
      if (this.order.savedMoneyTip) {
        return `<span class="pay-price__discount-value">${this.order.savedMoneyTip}</span>`
      }
      const discount = +this.order.total_discount_new?.amount
        ? this.order.total_discount_new?.amountWithSymbol
        : ''
      return discount
        ? template(
            `<span class="pay-price__discount-value">${discount}</span>`,
            this.language.SHEIN_KEY_PC_30926
        )
        : ''
    },
    // 新版未支付订单
    newUnpaidOrder() {
      return (
        this.showPreferentialFloor && [12, 13].includes(this.order.orderStatus)
      )
    },
    orderPrice() {
      return this.order.currencyTotalPrice
        ? this.order.currencyTotalPrice.amountWithSymbol
        : this.order.totalPrice
          ? this.order.totalPrice.amountWithSymbol
          : ''
    },
    orderStatus() {
      const language = this.language
      return {
        status: orderLogic.orderStatus(language),
        tips: {
          0: language.SHEIN_KEY_PC_15183,
          1: language.SHEIN_KEY_PC_15186,
          2: language.SHEIN_KEY_PC_15101,
          3: language.SHEIN_KEY_PC_15189,
          4: language.SHEIN_KEY_PC_16982,
          5: language.SHEIN_KEY_PC_17211,
          6: language.SHEIN_KEY_PC_15284,
          7: language.SHEIN_KEY_PC_15192,
          8: language.SHEIN_KEY_PC_15306,
          9: language.SHEIN_KEY_PC_15308,
          10: language.SHEIN_KEY_PC_15184,
          11: language.SHEIN_KEY_PC_15187,
          12: language.SHEIN_KEY_PC_15183,
          13: language.SHEIN_KEY_PC_15283,
          14: language.SHEIN_KEY_PC_15326,
          15: language.SHEIN_KEY_PC_15326,
          16: '',
          17: '',
          18: language.SHEIN_KEY_PC_15184,
          19: ''
        },
        pending: {
          paypal:
            language.SHEIN_KEY_PC_15496 + '' + language.SHEIN_KEY_PC_15497,
          'paypal-paypal':
            language.SHEIN_KEY_PC_15496 + '' + language.SHEIN_KEY_PC_15497
        },
        expired: {
          timeout: language.SHEIN_KEY_PC_15100,
          timeout_givesaway: language.SHEIN_KEY_PC_15101,
          out_of_stock: language.SHEIN_KEY_PC_15102,
          timeout_verify: language.SHEIN_KEY_PC_15285
        }
      }
    }
  },
  watch: {
    order: {
      handler(val) {
        if (!this.IS_RW && val.orderStatus == 5) {
          this.showConfirmDeliverTipsEvt(
            val,
            this.orderStatus.tips[val.orderStatus]
          )
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.orderSwiper()

    // 曝光埋点上报
    setTimeout(() => {
      daEventCenter.triggerNotice({
        daId: '1-22-2-1003',
        extraData: this.getAnaData()
      })
    }, 300)
  },
  methods: {
    handleOrderDetailClick() {
      daEventCenter.triggerNotice({
        daId: '1-22-2-1004',
        extraData: this.getAnaData()
      })
      this.toLink(
        `/user/orders/detail/${this.order.billno}${
          this.order.isOldOrder ? '?orderType=oldorder' : ''
        }`,
        'orderDetail'
      )
    },
    getAnaData() {
      return {
        order_id: this.order.billno,
        floor_type: this.showPreferentialFloor ? 'new_floor' : 'unpaid',
        is_first_review: '-',
        discount_status: this.showPreferentialFloor
          ? +this.order.total_discount_new?.amount
            ? 1
            : 0
          : '-'
      }
    },
    htmlDecode: ({ text, isHtml = true }) => htmlDecode({ text, isHtml }),
    transformImg: _transformImg,
    handleDate(date) {
      if (!date) return date
      const time = date * 1000
      const { SiteUID, dateLangMap } = this.commonInfo || {}
      const responseLocals = { SiteUID, dateLangMap }
      return `${timeTransformer(
        { time, sDateMap: ORDER_DATE },
        responseLocals
      )} ${timeTransformer({ time, defaultCode: 'G-1' }, responseLocals)}`
    },
    toLink(link, id) {
      analysisEvent(id)
      window.location.href = link
    },
    calculaNumber(goods) {
      var num = goods - 0 || 0
      if (num == 1) {
        num = num + ' ' + this.language.SHEIN_KEY_PC_15447
      } else {
        num = num + ' ' + this.language.SHEIN_KEY_PC_15448
      }
      return num
    },
    orderSwiper() {
      nextTick(() => {
        const swiperEl = this.$refs[`swiperRef_${this.index}`]
        if (!swiperEl) return

        const options = {
          injectStylesUrls: [
            `${PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`
          ],
          modules: [Navigation],
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 0,
          observer: true
        }

        if (this.order.orderGoodsList?.length > 1) {
          options.navigation = {
            prevEl: this.$refs[`swiperButtonPrev_${this.index}`],
            nextEl: this.$refs[`swiperButtonNext_${this.index}`]
          }
        }

        Object.assign(swiperEl, options)
        swiperEl.initialize()
      })
    },
    async showConfirmDeliverTipsEvt(order, text) {
      if (!text) return text
      let { confirmDeliveryBonusPoints: num = 0, billno } = order
      let isDoublePoints = false
      let { doublePoints = 0 } = await getOrderDoublePoints(billno)
      if (doublePoints) {
        num =
          parseInt(num + doublePoints) + '(' + num + '+' + doublePoints + ')'
        isDoublePoints = true
      } else {
        isDoublePoints = false
      }
      this.confirmDeliveryText = isDoublePoints
        ? text.replace('[num]', `<span style="color: #e64545;font-weight: bold;">${num}</span>`)
        : text.replace('[num]', num)
      this.confirmDeliverTipsBool = true
    }
  }
}
</script>
<style lang="less" scoped>
.order-item {
  &__wrapper {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #e5e5e5;
  }
  &__date {
    color: #666;
    font-size: 12px;
  }
  &__content {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  &__cell {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .swiper-button-disabled {
      opacity: 0.35;
    }

    &.align-left {
      align-items: flex-start;
    }
    .pay-price {
      &__value {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
      }

      &__value-new {
        color: #fa6338;
      }

      &__discount {
        display: flex;
        align-items: center;
        margin-top: 2.5px;
        font-size: 12px;
        line-height: 14px;
        color: var(---sui_color_gray_light1, #959595);
      }

      &__discount-value {
        margin-right: unset /* rtl: 2px */;
        margin-left: 2px /* rtl: unset */;
        color: #fa6338;
        font-weight: 700;
      }
    }
  }
  &__cell-img {
    padding: 7px 0 6px;
  }
  &__swiper-container {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    width: 104px;
  }
  &__swiper-slide {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 45px;
      height: 60px;
    }
  }
  &__swiper-button-prev,
  &__swiper-button-next {
    position: absolute;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: @zindex-hover;
    cursor: pointer;
  }
  &__swiper-button-prev {
    left: 17%;
  }
  &__swiper-button-next {
    right: 17%;
  }
  .swiper-button-disabled {
    opacity: 0.35;
  }

  &__cell-btn {
    color: @sui_color_link;
    text-transform: capitalize;
    cursor: pointer;
  }
}

.order-status-wrap {
  display: inline-block;
  .order-status {
    font-weight: normal;
    text-transform: capitalize;
  }
  .order-status__icon {
    display: inline-block;
    padding: 5px 0;
    font-size: 0;
    vertical-align: middle;

    i {
      font-size: 14px;
      color: @sui_color_gray_dark1;
    }

    .with-arrow-bottom {
      display: inline-block;
      height: 14px;
    }

    .tip-bottom {
      font-size: 14px;
    }
  }
}
.order-status__icon-ar > span.with-arrow-bottom:after {
  transform: translateX(50%) rotate(45deg);
  top: 18px;
}
</style>
