<template>
  <!-- 右侧栏 -->
  <div class="aside-content__wrapper">
    <NewsPannel
      v-if="newsList.length && visible"
      :data="newsList"
    />
    <CustomerService :language="language" />
    <AsideModule
      v-if="!isRisk"
      :language="language"
      :title="language.SHEIN_KEY_PC_14618"
      :data="wishList"
      @click-item="item => handleWishOrRecentItemClick(item, 'wishlist')"
      @click-more="toLink('/user/wishlist?pf=me', 'wishlistMore')"
    />
    <AsideModule
      v-if="!constantData.IS_RW"
      class="aside-content-follow"
      :language="language"
      :title="language.SHEIN_KEY_PC_25389"
      :label="followData.label.tagName"
      :show-rec-belt="followData.isByRec"
      :data="followData.list"
      :get-container-expose-data="() => getFollowExposeData('1-22-2-25')"
      :get-more-click-data="() => getFollowExposeData('1-22-2-24')"
      :get-item-expose-data="item => getFollowItemAnaData('1-22-2-29', item)"
      :get-item-click-data="item => getFollowItemAnaData('1-22-2-30', item)"
      @click-item="handleFollowItemClick"
      @click-more="handleFollowMoreClick"
    />
    <AsideModule
      v-if="recentViewList.length"
      :language="language"
      :title="language.SHEIN_KEY_PC_14565"
      :data="recentViewList"
      :show-zero-items="false"
      @click-item="item => handleWishOrRecentItemClick(item, 'recentView')"
      @click-more="toLink('/user/recently_view', 'recentViewMore')"
    />
  </div>
</template>

<script setup>
import { inject, onBeforeMount, reactive, ref } from 'vue'
import NewsPannel from './NewsPannel.vue'
import CustomerService from './CustomerService.vue'
import AsideModule from './AsideModule.vue'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import {
  queryNewsApi,
  queryWishAndFollowList,
  queryRecFollowList
} from '../../service/index'
import { useShowNews } from './utils/useShowNews'
import { useCommonState } from '../../utils/useCommonState'
// import { wishFollowData } from '../../utils/mock'

const toLink = inject('toLink', () => {})
const { userInfo, abtInfo, language, isRisk, constantData } = useCommonState()
// 文章页入口
const newsList = ref([])
const { handleShowNewsEntrance, visible } = useShowNews()
const getNewInfo = async () => {
  const res = await queryNewsApi()
  const content = res?.info?.content?.find(
    item => item.styleType == 'NEWS_SECTION'
  )
  const data = content?.props?.metaData?.articleTitles || []
  handleShowNewsEntrance(data, abtInfo.value)
  newsList.value = data
}
// 店铺follow wish
const wishList = ref([])
const followData = reactive({
  list: [],
  label: {},
  isByRec: false // 是否推荐返回
})
const getWishAndFollowList = async () => {
  const isOnRecFollowAbt =
    abtInfo.value.PageMeShopRecommend?.p?.PageMeShopRecommend === 'On'
  const requestQueue = [queryWishAndFollowList()]
  if (isOnRecFollowAbt) {
    requestQueue.push(queryRecFollowList())
  }
  const [wishFollowRes, recFollowRes] = await Promise.all(requestQueue)
  // const res = wishFollowData
  const data = wishFollowRes?.info || {}
  const wishListRes = data.getWishlistByFilterRspBO?.products || []
  wishList.value = wishListRes.map(n => ({
    goodsImage: n.goods_img,
    originData: n
  }))
  let followListRes = data.storeListRspDO?.storeInfoList || []
  // 用户没关注且abt开启，才用推荐返回的
  followData.isByRec = !followListRes.length && isOnRecFollowAbt
  if (followData.isByRec) {
    followListRes = recFollowRes?.info?.storeInfoList || []
  }
  // eslint-disable-next-line require-atomic-updates
  followData.list = followListRes.map(n => {
    return {
      goodsImage: n.shopRecProducts?.[0]?.goods_img,
      title: followData.isByRec ? '' : n.title,
      originData: n
    }
  })
  if (followData.isByRec) return
  // eslint-disable-next-line require-atomic-updates
  followData.label = data.storeListRspDO?.tagsCloudList?.[0] || {}
  checkHasClickedDot()
}
// recent view
const recentViewList = ref([])
const getRecentViewList = () => {
  const cacheData = localStorage.getItem('pc_recent_views')
  try {
    const data = JSON.parse(cacheData) || []
    recentViewList.value = data.map(n => ({
      goodsImage: n.goods_img,
      originData: n
    }))
  } catch {
    recentViewList.value = []
  }
}
onBeforeMount(() => {
  getNewInfo()
  getWishAndFollowList()
  getRecentViewList()
})

/** follow */
// 计算时间相隔是否在三天内
const isWithinThreeDays = (pre, now) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  const differenceInDays = Math.abs((now - pre) / millisecondsPerDay)
  return differenceInDays <= 3
}
// 检查是否三天内点击过【new】
const isNewClicked = ref(false)
const isPromoClicked = ref(false)
const checkHasClickedDot = () => {
  const newCache = window.localStorage.getItem(
    `CLICK_NEW_BUTTON_TIME_${userInfo.value.member_id}`
  )
  const promoCache = window.localStorage.getItem(
    `CLICK_PROMO_BUTTON_TIME_${userInfo.value.member_id}`
  )
  const now = new Date().getTime()
  if (newCache) {
    isNewClicked.value = isWithinThreeDays(newCache, now)
  }
  if (promoCache) {
    isPromoClicked.value = isWithinThreeDays(promoCache, now)
  }
}
const handleFollowMoreClick = (product = {}, activeIndex = 0) => {
  if (followData.label.tagName) {
    if (!isPromoClicked.value && followData.label.tagId === '2') {
      window.localStorage.setItem(
        `CLICK_PROMO_BUTTON_TIME_${userInfo.value.member_id}`,
        new Date().getTime()
      )
    } else if (!isNewClicked.value && followData.label.tagId === '1') {
      window.localStorage.setItem(
        `CLICK_NEW_BUTTON_TIME_${userInfo.value.member_id}`,
        new Date().getTime()
      )
    }
  }
  window.location.href = getFollowPageUrl(product, activeIndex)
}
// followlist页面传递adp置顶
const getFollowPageRecAdpParams = (product = {}, index, activeIndex = 0) => {
  let adpStores = []
  const followList = [...followData.list]
  // 点击的商品
  if (product?.goods_id) {
    // 置顶点击商品所在的轮播页
    adpStores = followList.slice(activeIndex, activeIndex + 3)
    // 置顶点击项目
    adpStores.unshift(followList.splice(index, 1)[0])
  }
  const adp = []
  adpStores.forEach(({ originData }) => {
    const product = originData.shopRecProducts?.[0] || {}
    if (originData.contentCarrierId && product.goods_id) {
      adp.push(`${originData.contentCarrierId}:${product.goods_id}`)
    }
  })
  return { recommend_adps: adp.join('+') }
}
const getFollowPageAdpParams = () => {
  // adp 最多传递七个出去
  const storeCodes = followData.list.map(item => item.store_code)
  const uniqueStoreCodes = new Set(storeCodes)
  const hasSameStoreCode = uniqueStoreCodes.size !== storeCodes.length
  const params = {}
  if (hasSameStoreCode) {
    const key = followData.list[0]?.store_code
    const adps = followData.list
      .map(item => item.shopRecProducts?.[0]?.goods_id)
      .slice(0, 7)
    params[key] = adps.join(',')
  } else {
    followData.list.forEach(item => {
      const key = item.store_code
      params[key] = item.shopRecProducts?.[0]?.goods_id
    })
  }
  return { adps: encodeURIComponent(JSON.stringify(params)) }
}
const getFollowPageUrl = (product = {}, activeIndex = 0) => {
  const baseUrl = '/store/following'
  if (!followData.list.length) return baseUrl
  const searchParams = new URLSearchParams({
    ...(followData.isByRec
      ? getFollowPageRecAdpParams(product, activeIndex)
      : getFollowPageAdpParams()),
    page_from: 'page_me',
    src_module: 'me_follow_store',
    src_identifier: 'on=me_follow_store'
  })
  return `${baseUrl}?${searchParams.toString()}`
}
const handleFollowItemClick = ({ data = {}, activeIndex } = {}) => {
  const product = data.shopRecProducts?.[0] || {}
  // 推荐返回的关注列表，点击商品也是跳转关注页
  if (followData.isByRec) {
    return handleFollowMoreClick(product, activeIndex)
  }
  const url = getGoodsUrl(
    product.goods_url_name,
    product.goods_id,
    product.cat_id
  )
  window.location.href = url
}
const getFollowExposeData = id => ({
  id,
  data: {
    activity_from: 'goods',
    is_recommend: followData.isByRec ? 1 : 0
  }
})

const getFollowItemAnaData = (id, item) => ({
  id: id,
  data: {
    shop_code: item.store_code,
    goods_id: item.shopRecProducts?.[0]?.goods_id,
    shop_tag: followData.label.tagName
  }
})

/** recent view */
const handleWishOrRecentItemClick = ({ data }, type) => {
  const { goods_id, goods_url_name, cat_id, mall_code } = data
  if (!goods_id) return
  const url = getGoodsUrl(goods_url_name, goods_id, cat_id)
  toLink(url + (mall_code ? `?mallCode=${mall_code}` : ''), type)
}
</script>

<style lang="less">
.aside-content__wrapper {
  flex: 0 0 auto;
  width: 286px;

  .aside-content-follow {
    .aside-module__goods-item {
      height: 90px;
    }
  }
}
</style>
