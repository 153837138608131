<template>
  <AlertTips
    :tips-content="tipsContent"
    :arrow-position="1"
    @on-click="onTipsClick"
    @on-close="onTipsClose"
  />
</template>

<script setup name="ExpireCouponTips">
import { computed } from 'vue'
import { htmlDecode } from '@shein/common-function'
import AlertTips from './AlertTips.vue'
const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  couponOrPointsData: {
    type: Object,
    default: () => ({}),
  },
})
const emits = defineEmits([
  'on-close',
  'on-click',
])
const tipsContent = computed(() => htmlDecode({
  text: props.language?.SHEIN_KEY_PC_19363.replace(
    '{1}',
    props.couponOrPointsData?.couponNum
  )
}))
const onTipsClick = () => emits('on-click')
const onTipsClose = () => emits('on-close')
</script>

