import { 
  EXPIRE_GAME_MONEY_CACHE_KEY,
  ExpireTipsClickStatus,
} from 'public/src/pages/user_index/constants'
import { getLocalStorage } from 'public/src/pages/user_index/utils/storage.js'
import { isLogin } from 'public/src/pages/common/utils'

export const getUserAssetsBottomContent = ({
  userInfo,
  expireGameMoney,
  couponOrPointsData,
  isHideModuleAction = false
}) => {
  const controls = {
    showExpireGameMoney: false,
    showExpireCouponTips: false,
    showExpirePointTips: false,
  }
  if (isHideModuleAction) {
    // 如果是显式隐藏模块，则隐藏后续模块
    return controls
  }
  initExpireGameMoneyTipsStatus({ controls, userInfo, expireGameMoney })
  if (controls.showExpireGameMoney) {
    return controls
  }
  initExpireCouponTipsStatus({ controls, couponOrPointsData })
  if (controls.showExpireCouponTips) {
    return controls
  }
  initExpirePointTipsStatus({ controls, couponOrPointsData })

  return controls
}

export const getExpireGameMoneyCacheKey = (userInfo) => {
  const userId = isLogin() ? userInfo?.member_id : 'anonymous'
  return `${EXPIRE_GAME_MONEY_CACHE_KEY}_${userId}`
}

export const getExpireGameMoneyCache = (userInfo) => {
  const cacheKey = getExpireGameMoneyCacheKey(userInfo)
  return getLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.CAN_BE_CLOSED,
    closeTimes: 0,
  })
}

const initExpireGameMoneyTipsStatus = ({
  controls,
  userInfo,
  expireGameMoney,
}) => {
  if (!expireGameMoney) return
  if (!expireGameMoney?.expireAmountWithSymbol || !expireGameMoney?.maxCloseTimes) return
  const expireTipCache = getExpireGameMoneyCache(userInfo)
  if (expireTipCache.status === ExpireTipsClickStatus.NO_LONGER_SHOW) return
  const { maxCloseTimes } = expireGameMoney
  const reachMaxCloseTimes = expireTipCache.closeTimes >= maxCloseTimes
  if (reachMaxCloseTimes) return
  const now = Date.now()
  controls.showExpireGameMoney = expireTipCache.closeTimes === 0 || now > expireTipCache.displayAfterTime
}

const initExpireCouponTipsStatus = ({
  controls,
  couponOrPointsData,
} = {}) => {
  const { couponNum, isOpenCoupon, isClickCoupon } = couponOrPointsData || {}
  const show = isOpenCoupon && !isClickCoupon && +couponNum >= 1
  controls.showExpireCouponTips = show
}

const initExpirePointTipsStatus = ({
  controls,
  couponOrPointsData,
} = {}) => {
  const { pointsNum, isOpenPoints, isClickPoints } = couponOrPointsData || {}
  const show = isOpenPoints && !isClickPoints && +pointsNum >= 50
  controls.showExpirePointTips = show
}


