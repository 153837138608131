<template>
  <div>
    <!-- 游戏赠送钱包金额临过期提示 -->
    <ExpireGameMoneyTips
      v-if="moduleControls.showExpireGameMoney"
      :language="language"
      :user-info="userInfo"
      :expire-game-money="expireGameMoney"
      @hide-module="updateModule({ isHideModuleAction: true })"
    />
    <!-- 临过期优惠券 -->
    <ExpireCouponTips
      v-else-if="moduleControls.showExpireCouponTips"
      :language="language"
      :coupon-or-points-data="couponOrPointsData"
      @on-click="emitsEventAndHideModule('on-expire-coupon-click')"
      @on-close="emitsEventAndHideModule('on-expire-coupon-close')"
    />
    <!-- 临过期积分 -->
    <ExpirePointTips
      v-else-if="moduleControls.showExpirePointTips"
      :language="language"
      :coupon-or-points-data="couponOrPointsData"
      @on-click="emitsEventAndHideModule('on-expire-points-click')"
      @on-close="emitsEventAndHideModule('on-expire-points-close')"
    />
  </div>
</template>

<script setup name="UserAssetsBottomContent">
import { ref, onMounted } from 'vue'
import ExpireGameMoneyTips from './ExpireGameMoneyTips.vue'
import ExpireCouponTips from './ExpireCouponTips.vue'
import ExpirePointTips from './ExpirePointTips.vue'
import { getUserAssetsBottomContent } from './user-assets-bottom'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  userInfo: {
    type: Object,
    default: () => ({}),
  },
  expireGameMoney: {
    type: Object,
    default: null,
  },
  couponOrPointsData: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'on-expire-coupon-click',
  'on-expire-coupon-close',
  'on-expire-points-click',
  'on-expire-points-close',
])

const emitsEventAndHideModule = (eventName) => {
  emits(eventName)
  updateModule({ isHideModuleAction: true })
}

const moduleControls = ref({})
onMounted(() => {
  updateModule()
})

const updateModule = ({
  isHideModuleAction = false,
} = {}) => {
  moduleControls.value = getUserAssetsBottomContent({
    userInfo: props.userInfo,
    expireGameMoney: props.expireGameMoney,
    couponOrPointsData: props.couponOrPointsData,
    isHideModuleAction,
  })
}
</script>
