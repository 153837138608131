import { createSSRApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import store from '../common/messageStore/index'
import UserCenterContainer from './index-new.vue'
import App from './index.vue'
import { Icon } from '@shein-aidc/icon-vue3'

// const FinalApp = typeof window !== 'undefined' && window.gbUserIndexData?.useBffApi ? AppNew : App
export function _createApp(context) {

  const FinalApp = context.useBffApi ? UserCenterContainer : App

  const app = createSSRApp(FinalApp, {
    context,
  })
  registerBaseContext(app)
  app.use(store)
  app.component('SvgIcon', Icon)

  return { app, store }
}
