<template>
  <div class="customer-service__wrapper">
    <div class="customer-service__title">
      {{ language.SHEIN_KEY_PC_15732 }}
    </div>
    <ul class="customer-service__content">
      <li
        v-for="item in list"
        :key="item.id"
        v-enterkey
        class="customer-service__item"
        :aria-label="item.title"
        @click="item.onClick?.(item) || toLink(item.link, item.id)"
      >
        <span class="customer-service__item-icon">
          <Icon
            :name="item.icon"
            :size="'28px'"
            :da-event-expose="item.daEventExpose"
            :data-unread="item.daEventUnread"
          />
          <i
            v-if="item.num && !isRisk"
            class="customer-service__item-badge"
          >
            <em>{{ formatNum(item.num) }}</em>
          </i>
          <i
            v-else-if="item.showDot"
            class="customer-service__item-circle-badge"
          ></i>
        </span>
        <span class="customer-service__item-text">{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { onBeforeMount, reactive, computed, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { queryUnreadTicketNum, queryUnreadMessageNum } from '../../service'
import { useCommonState } from '../../utils/useCommonState'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const toLink = inject('toLink', () => {})
const { language, isRisk } = useCommonState()
const state = reactive({
  unReadMessageNum: 0,
  unReadTicketNum: 0,
  isMsgShowDot: true
})
const list = computed(() => [
  {
    id: 'message',
    title: language.value.SHEIN_KEY_PC_16007?.toLowerCase(),
    icon: 'sui_icon_notice_28px',
    num: state.unReadMessageNum,
    link: '/user/message',
    onClick(item) {
      daEventCenter.triggerNotice({
        daId: '1-22-2-3',
        extraData: {
          unread_amount: item.num,
          category: 'My Account'
        }
      })
      toLink(item.link, item.id)
    },
    showDot: state.isMsgShowDot,
    daEventExpose: '1-22-2-6',
    daEventUnread: state.unReadMessageNum || state.isMsgShowDot ? 1 : 0
  },
  {
    id: 'records',
    title: language.value.SHEIN_KEY_PC_15144,
    icon: 'sui_icon_survey_28px',
    num: state.unReadTicketNum,
    link: '/user/tickets',
    showDot: false,
    daEventExpose: ''
  }
])
onBeforeMount(() => {
  getUnreadMessageNum()
  getUnreadTickedNum()
})
const formatNum = num => (num > 99 ? '99+' : num || 0)
// 获取站内信未读信息
const getUnreadMessageNum = async () => {
  const res = await queryUnreadMessageNum()
  const { news = {}, order = {}, activity = {}, promo = {} } = res?.info || {}
  const unReadNewsAndOrderNum = Number(news.num || 0) + Number(order.num || 0)
  const unReadActivityNum = activity.is_show ? Number(activity.num) : 0
  const unReadPromoNum = promo.is_show ? Number(promo.num) : 0
  state.unReadMessageNum = unReadNewsAndOrderNum
  state.isMsgShowDot = Boolean(unReadActivityNum + unReadPromoNum)
  daEventCenter.triggerNotice({
    daId: '1-22-2-6',
    extraData: {
      if_unread: state.unReadMessageNum || state.isMsgShowDot ? 1 : 0
    }
  })
}
// 查询TK&Chat总未读数
const getUnreadTickedNum = async () => {
  const res = await queryUnreadTicketNum()
  state.unReadTicketNum = +res?.info?.num || 0
}
</script>
<style lang="less">
.customer-service {
  &__wrapper {
    padding: 20px;
    height: 180px;
    background: #fff;

    & when (@IS_RW) {
      border-radius: 4px;
    }
  }

  &__title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }

  &__content {
    display: flex;
    gap: 10px;
    margin-top: 40px;
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
      .customer-service__item-text {
        color: #222;
      }
    }
  }

  &__item-icon {
    position: relative;
  }

  &__item-badge {
    position: absolute;
    top: -6px;
    text-align: center;
    background: @sui_color_guide;
    color: #fff;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    border-radius: 8px;
    padding: 0 4px;
    background-clip: padding-box;
    vertical-align: middle;
  }

  &__item-circle-badge {
    position: absolute;
    top: -1px;
    right: -4px;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    border: 0;
    & when (@IS_SH) {
      background: #ff411c;
    }
    & when (@IS_RW) {
      background: @color_brand;
    }
  }

  &__item-text {
    margin-top: 10px;
    color: #666;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
