<template>
  <div>
    <ClientOnly>
      <common-notice
        v-if="showCccNotice"
        class="ccc-remind"
        :message="cccNoticeContent"
        @close-alert="closeCccNotice"
        @show-detail-evt="exposeCccDetail"
      />
    </ClientOnly>

    <!-- 爆破黑名单和泄露名单修改密码弹窗 -->
    <ClientOnly>
      <s-dialog
        :visible="showRiskDialog"
        class="user-risk-dialog userIndex__member-risk-dialog"
        :type="'W720'"
        :show-close="true"
        :close-on-click-modal="false"
        :immediately-render="true"
        @close="showRiskDialog = false"
      >
        <risk-confirm
          ref="riskDialog"
          :is-dialog="true"
          @verify-suc-callback="loginModalSucCb"
          @confirm-callback="showRiskDialog = false"
        />
      </s-dialog>
    </ClientOnly>

    <!-- 完善偏好设置, 送积分 -->
    <ClientOnly>
      <s-alert
        v-if="!IS_RW && showPreference"
        key="preference"
        show-icon
        type="info"
        :rtl-icon-roate="lang == 'ar' ? true : false"
        class="preference-remind"
        @click="jumpableNotice"
      >
        <div tabindex="0">
          {{ language.SHEIN_KEY_PC_18208.replace('{0}', preferencePoints) }}
        </div>
        <template #operation>
          <Icon
            color="#999"
            :is-rotate="GB_cssRight"
            name="sui_icon_more_right_12px_1"
            class="icon-jumpable"
          />
        </template>
      </s-alert>
    </ClientOnly>

    <div class="c-my-account-new">
      <!-- 主内容 -->
      <div class="content">
        <div
          v-if="supplement"
          class="announce"
          role="tooltip"
          tabindex="0"
          :aria-label="supplement"
        >
          <Icon
            name="sui_icon_news_16px"
            color="#999"
            size="18px"
          />
          <span class="desc">{{ supplement }}</span>
          <Icon
            v-enterkey
            :name="IS_RW ? 'sui_icon_close_16px_1' : 'sui_icon_closed_14px_1'"
            class="close-icon"
            is-responsive-name
            role="button"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_15522"
            size="16px"
            color="#999"
            @click="supplement = ''"
          />
        </div>
        <div class="my-assets">
          <div
            v-enterkey
            class="user-info"
            @click="toLink('/user/account_setting', 'profile')"
          >
            <div class="nickname-content">
              <div class="nickname">
                <span
                  class="name fsp-element"
                  :class="IS_RW ? 'font' : ''"
                  role="heading"
                  tabindex="0"
                >
                  {{ language.SHEIN_KEY_PC_15746 }},{{
                    htmlDecode({ text: nickname })
                  }}
                </span>
                <ClientOnly>
                  <img
                    v-if="isStudent"
                    class="student"
                    :src="
                      PUBLIC_CDN + '/she_dist/images/student-5ad6688997.png'
                    "
                    alt=""
                  />
                </ClientOnly>
                <ClientOnly>
                  <div
                    v-if="!!userInfo.member_id"
                    class="vip"
                    @click.stop="
                      toLink(IS_RW ? '/user/romwevip' : '/user/sheinvip', 'vip')
                    "
                  >
                    <vip-medal 
                      size="S"
                      @set-level="handleSetLevel"
                    />
                  </div>
                </ClientOnly>
                <div
                  v-if="isPrime"
                  class="vip"
                  @click.stop="toLink('/user/prime?productsource=me', 'prime')"
                >
                  <club-badge :config="{ IMG_LINK, GB_cssRight }" />
                </div>
                <div
                  v-if="isShowSaverIcon"
                  class="shein-extra_badge"
                ></div>
              </div>
            </div>
            <div
              class="nickname-content-right"
              role="link"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PC_16195 }}
              <Icon
                size="12px"
                :is-rotate="GB_cssRight"
                class="mt__2"
                name="sui_icon_more_right_12px"
                color="#666"
              />
            </div>
          </div>
          <div class="assets">
            <div class="title eye-title">
              <span class="header-title"></span>
              <Icon
                v-if="!isRisk"
                is-responsive-name
                class="click_able"
                :is-rotate="GB_cssRight"
                :name="
                  isShowAssets
                    ? 'sui_icon_eyes_open_16px'
                    : 'sui_icon_eyes_closed_16px'
                "
                @click="toggleShowAssets"
              />
            </div>
            <ul class="tabs">
              <li
                v-for="asset in assets"
                :key="asset.id"
                v-enterkey
                class="tab-item"
                :class="{ 'is-show': isShowAssets }"
                :title="asset.title"
                role="link"
                tabindex="0"
                :aria-label="asset.title"
                @click="toLink(asset.link, asset.id)"
              >
                <component
                  :is="asset.extra"
                  v-if="asset.showIcon"
                  size="32px"
                  height="36px"
                />

                <span
                  v-else
                  class="tab-extra"
                  v-html="asset.extra"
                ></span>
                <span class="tab-title fsp-element">{{ asset.title }}</span>
              </li>
            </ul>
            <UserAssetsBottom
              v-if="isUserAssetsBottomDataReady"
              :language="language"
              :user-info="userInfo"
              :expire-game-money="expireGameMoney"
              :coupon-or-points-data="couponOrPointsData"
              @on-expire-coupon-click="toLink('/user/coupon', 'coupon')"
              @on-expire-coupon-close="pointsOrCouponJumpEvt('coupon', 2)"
              @on-expire-points-click="toLink('/user/points', 'points')"
              @on-expire-points-close="pointsOrCouponJumpEvt('points', 2)"
            />
          </div>
          <!-- 会员模块 -->
          <div :class="{ 'vip-modules': isShowSaver && isShowPrime }">
            <!-- 付费会员 -->
            <prime-pannel
              v-if="isShowPrime"
              :is-show-saver="isShowSaver"
              :language="language"
              :prime-info-three-for-one="primeInfoThreeForOne"
              :saved-info="currentUserSavedInfo"
            />
            <!-- 超省卡 -->
            <extra-pannel
              v-if="isShowSaver"
              :is-show-prime="isShowPrime"
              :language="language"
              :info="saverInfoThreeForOne"
              :is-open-abt="isSaverEnable"
            />
          </div>
        </div>
        <div class="my-orders">
          <div class="title fsp-element">
            <span
              class="header-title"
              :class="IS_RW ? 'font' : ''"
              role="heading"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PC_14895 }}
            </span>
            <span
              v-enterkey
              class="more"
              role="link"
              tabindex="0"
              :aria-label="language.SHEIN_KEY_PC_14663"
              @click="toLink('/user/orders/list', 'allOrder')"
            >
              {{ language.SHEIN_KEY_PC_14663 }}
              <sui_icon_more_right_12px
                size="12px"
                :is-rotate="GB_cssRight"
                class="mt__2"
              />
            </span>
          </div>
          <ul class="orders-tab">
            <li
              v-for="tab in ordersTab"
              :key="tab.id"
              v-enterkey
              class="tab-item"
              :title="tab.title"
              role="link"
              tabindex="0"
              :aria-label="tab.title"
              @click="toLink(tab.link, tab.id, tab.extra)"
            >
              <span class="tab-top">
                <component
                  :is="tab.icon"
                  class="tab-icon"
                  size="32px"
                />

                <span
                  v-if="tab.extra && !isRisk"
                  class="tab-badge badge"
                >
                  <em>{{ badgeNum_filter(tab.extra) }}</em>
                </span>
              </span>
              <span class="tab-title fsp-element">{{ tab.title }}</span>
            </li>
          </ul>
          <!-- 待评论订单 -->
          <ClientOnly>
            <div
              v-if="unReviewList.length"
              class="unreview-order fsp-element"
              @click="goToReviewDetail(unReviewList?.[0])"
            >
              <img
                class="unreview-order__logo"
                :src="`${PUBLIC_CDN}/she_dist/images/review_center/union-53f194fd65.png`"
              />
              <div class="unreview-order__info">
                <div class="unreview-order__content">
                  <img
                    class="unreview-order__icon"
                    :src="unReviewList?.[0]?.['img']"
                    alt=""
                  />
                  <div
                    class="unreview-order__text"
                    v-html="unReviewList?.[0]?.['content']"
                  ></div>
                </div>
                <s-button :type="['H28PX', 'primary']">
                  {{ language.SHEIN_KEY_PC_27373 }}
                </s-button>
              </div>
            </div>
          </ClientOnly>
          <!-- 订单列表 -->
          <div
            v-if="!orderList"
            class="load-ctn la-ball-pulse la-ball-pulse-2x la-ball-pulse-black"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <template v-else>
            <ul
              v-if="orderList.length"
              class="order-list fsp-element"
            >
              <order-item
                v-for="(order, index) in orderList"
                :key="index"
                :language="language"
                :order="order"
                :index="index"
                :show-preferential-floor="showPreferentialFloor"
              />
            </ul>
            <div
              v-else-if="!isRisk"
              class="oops fsp-element"
            >
              <Icon
                v-if="IS_RW"
                name="sui_img_nodata_list_75px"
                size="75px"
                class="icon-main"
              />

              <Icon
                v-else
                size="75px"
                name="sui_icon_empty_75px_1"
              />

              <p class="desc">
                {{ language.SHEIN_KEY_PC_15962 }}
              </p>
            </div>
          </template>
        </div>
        <ClientOnly>
          <div class="my-recommend">
            <RecommendCcc v-if="isShowRecommend" />
          </div>
        </ClientOnly>
      </div>
      <!-- 右侧栏 -->
      <div class="aside">
        <NewsPannel
          v-if="!IS_RW && newsInfo?.isShowNewsEntrance && newsInfo?.newsEntranceList?.length"
          :language="language"
          :news-info="newsInfo"
          :context="{
            langPath,
            GB_cssRight
          }"
        />
        <div class="service">
          <div
            class="header-title"
            :class="IS_RW ? 'font-bold' : ''"
            role="heading"
            tabindex="0"
          >
            {{ language.SHEIN_KEY_PC_15732 }}
          </div>
          <ul
            class="tabs"
            da-expose-code="userIndexExpose"
          >
            <li
              v-for="tab in services"
              :key="tab.id"
              v-enterkey
              class="tab-item"
              :title="tab.title"
              role="link"
              tabindex="0"
              :aria-label="tab.title"
              @click="toLink(tab.link, tab.id)"
            >
              <span class="tab-top">
                <component
                  :is="tab.icon"
                  size="28px"
                  :da-event-expose="tab.daEventExpose"
                  :data-unread="tab.daEventUnread"
                />
                <i
                  v-if="tab.extra && !isRisk"
                  class="tab-badge badge"
                >
                  <em>{{ badgeNum_filter(tab.extra) }}</em>
                </i>
                <i
                  v-else-if="tab.isMsgActivityAndPromoOnly"
                  class="msg-activity-only"
                ></i>
              </span>
              <span class="tab-title">{{ tab.title }}</span>
            </li>
          </ul>
        </div>
        <!-- wishlist -->
        <div
          v-if="!isRisk"
          class="goods wishlist"
          :class="{ pagination: wishlists.length > 1 }"
        >
          <div class="title">
            <span
              class="header-title"
              :class="IS_RW ? 'font-bold' : ''"
              role="heading"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PC_14618 }}
            </span>
            <span
              v-enterkey
              class="more"
              role="link"
              tabindex="0"
              @click="toLink('/user/wishlist?pf=me', 'wishlistMore')"
            >
              {{
                wishlists.length
                  ? language.SHEIN_KEY_PC_15663
                  : `0 ${language.SHEIN_KEY_PC_29598}`
              }}
              <sui_icon_more_right_12px
                size="12px"
                :is-rotate="GB_cssRight"
                class="mt__2"
              />
            </span>
          </div>
          <div
            v-if="wishlists.length"
            class="lists-wrap"
          >
            <div class="lists">
              <swiper-container
                ref="wishlistSwiperRef"
                init="false"
                pagination="true"
                :dir="GB_cssRight ? 'rtl' : 'ltr'"
                class="lists__swiper-container"
              >
                <template
                  v-for="(wish, index) in wishlists"
                  :key="index"
                >
                  <swiper-slide
                    v-for="item in wish"
                    :key="item && item.goodsId && item.goodsId.goods_id"
                    class="lists__swiper-slide img-panel"
                    @click="
                      toLink(
                        getGoodsUrl(
                          item && item.goodsId && item.goodsId.goods_url_name,
                          item.goodsId && item.goodsId.goods_id,
                          item.goodsId && item.goodsId.cat_id
                        ) +
                          (item && item.goodsId && item.goodsId.mall_code
                            ? `?mallCode=${
                              item && item.goodsId && item.goodsId.mall_code
                            }`
                            : ''),
                        'wishlist'
                      )
                    "
                  >
                    <img
                      :class="[{lazyload: !!index }]"
                      :src="!!index ? IMG_LINK?.LAZY_IMG : cutImg(item?.goodsId?.goods_thumb)"
                      :data-src="cutImg(item?.goodsId?.goods_thumb)"
                      :style="{ width: '100%', height: 'auto' }"
                      alt=""
                    />
                  </swiper-slide>
                </template>
              </swiper-container>
              <template v-if="wishlists.length > 1">
                <div
                  ref="wishlistPrevRef"
                  class="lists__swiper-prev"
                >
                  <Icon
                    :is-rotate="GB_cssRight"
                    :name="
                      IS_RW
                        ? 'sui_icon_left_48px_1'
                        : 'sui_icon_more_left_12px_1'
                    "
                    size="16px"
                    is-responsive-name
                  />
                </div>
                <div
                  ref="wishlistNextRef"
                  class="lists__swiper-next"
                >
                  <Icon
                    size="16px"
                    width="16px"
                    height="16px"
                    :is-rotate="GB_cssRight"
                    color="#222"
                    :name="
                      IS_RW
                        ? 'sui_icon_right_48px_1'
                        : 'sui_icon_more_right_12px_1'
                    "
                    is-responsive-name
                  />
                </div>
              </template>
            </div>
          </div>

          <div
            v-else
            class="oops"
          >
            <div v-if="IS_RW">
              <Icon
                v-if="IS_RW"
                name="sui_img_nodata_save_75px"
                size="75px"
                class="icon-main"
              />

              <p class="desc">
                {{ language.SHEIN_KEY_PC_16732 }}
              </p>
            </div>
          </div>
        </div>
        <FollowLists
          v-if="!IS_RW"
          :new-arrival-res="newArrivalRes"
          :follow-data="followData"
        />
        <!-- recent-view  -->
        <ClientOnly>
          <div
            v-if="!isRisk && recentViews.length"
            class="goods recent-view"
            :class="{ pagination: recentViews.length > 1 }"
          >
            <div class="title">
              <span
                class="header-title"
                :class="IS_RW ? 'font-bold' : ''"
                role="heading"
                tabindex="0"
              >
                {{ language.SHEIN_KEY_PC_14565 }}
              </span>
              <span
                v-enterkey
                class="more"
                role="link"
                tabindex="0"
                @click="toLink('/user/recently_view', 'recentViewMore')"
              >
                {{ language.SHEIN_KEY_PC_15663 }}
                <Icon
                  size="12px"
                  color="#666"
                  class="mt__2"
                  name="sui_icon_more_right_12px"
                  :is-rotate="GB_cssRight"
                />
              </span>
            </div>
            <div
              v-if="recentViews.length"
              class="lists-wrap"
            >
              <div
                v-if="recentViews.length"
                class="lists"
              >
                <swiper-container
                  ref="recentSwiperRef"
                  init="false"
                  pagination="true"
                  :dir="GB_cssRight ? 'rtl' : 'ltr'"
                  class="lists__swiper-container"
                >
                  <template
                    v-for="(view, index) in recentViews"
                    :key="index"
                  >
                    <swiper-slide
                      v-for="item in view"
                      :key="item && item.goodsId && item.goodsId.goods_id"
                      class="lists__swiper-slide img-panel"
                      @click="
                        toLink(
                          getGoodsUrl(
                            item && item.goodsId && item.goodsId.goods_url_name,
                            item.goodsId && item.goodsId.goods_id,
                            item.goodsId && item.goodsId.cat_id
                          ) +
                            (item && item.mall_code
                              ? `?mallCode=${item.mall_code}`
                              : ''),
                          'recentView'
                        )
                      "
                    >
                      <img
                        :class="{lazyload: !!index}"
                        :src="!!index ? IMG_LINK?.LAZY_IMG : transformImg({img: item?.goodsId?.goods_thumb})"
                        :data-src="transformImg({img: item?.goodsId?.goods_thumb})"
                        :style="{ width: '100%', height: 'auto' }"
                        alt=""
                      />
                    </swiper-slide>
                  </template>
                </swiper-container>
                <template v-if="recentViews.length > 1">
                  <div
                    ref="recentPrevRef"
                    class="lists__swiper-prev"
                  >
                    <Icon
                      :is-rotate="GB_cssRight"
                      :name="
                        IS_RW
                          ? 'sui_icon_left_48px_1'
                          : 'sui_icon_more_left_12px_1'
                      "
                      size="16px"
                      is-responsive-name
                    />
                  </div>
                  <div
                    ref="recentNextRef"
                    class="lists__swiper-next"
                  >
                    <Icon
                      size="16px"
                      :is-rotate="GB_cssRight"
                      width="16px"
                      height="16px"
                      color="#222"
                      :name="
                        IS_RW
                          ? 'sui_icon_right_48px_1'
                          : 'sui_icon_more_right_12px_1'
                      "
                      is-responsive-name
                    />
                  </div>
                </template>
              </div>
            </div>
            <div
              v-else
              class="oops"
            >
              <!-- <img class="empty" v-if="IS_RW" :src="IMG_LINK['no-find']" alt=""> -->
              <Icon
                v-if="IS_RW"
                name="sui_img_nodata_default_75px"
                size="75px"
              />
              <Icon
                v-else
                size="75px"
                name="sui_icon_empty_75px_1"
              />

              <p class="desc">
                {{ language.SHEIN_KEY_PC_16983 }}
              </p>
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>
    <ClientOnly>
      <component
        :is="isShowNewUnpaidDialog ? 'UnpaidDialogV2' : 'UnpaidDialog'"
        :language="language"
        :order-item="dialogOrderItem"
        :configs="USER_CENTER_UNPAID_ORDER_CONFIG"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { nextTick, defineAsyncComponent } from 'vue'
import mitt from 'mitt'
import {
  Icon,
  sui_icon_more_right_12px,
  sui_icon_me_coupon_32px,
  sui_icon_me_points_32px,
  sui_icon_me_wallet_32px,
  sui_icon_me_gift_32px,
  sui_icon_me_unpaid_32px,
  sui_icon_me_processing_32px,
  sui_icon_me_shipped_32px,
  sui_icon_me_review_32px,
  sui_icon_me_returns_32px,
  sui_icon_survey_28px,
  sui_icon_notice_28px
} from '@shein-aidc/icon-vue3'
import { mapMutations } from 'vuex'
import orderItem from './components/orderItem.vue'
import { analysisEvent } from './analysis'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter'
import { pointsOrCouponMixins } from '../common/user_mixins/pointsOrCouponMixins'
import { InitFtoken } from 'public/src/pages/common/ftoken/index'
import {
  htmlDecode,
  transformImg as _transformImg,
  getQueryString,
  setCookie,
  getCookie,
  getLocalStorage,
  setLocalStorage,
  template,
  discountPercentNum
} from '@shein/common-function'
import commonNotice from '../common/commonNotice/index.vue'
import UserAssetsBottom from './components/user_assets_bottom/ModuleIndex.vue'
import PrimePannel from './components/primePannel.vue'
import ExtraPannel from './components/extraPannel.vue'
import FollowLists from './components/FollowLists.vue'
import NewsPannel from './components/NewsPannel.vue'
import ClubBadge from 'public/src/pages/components/clubBadge'
import { getGoodsUrl, isLogin } from 'public/src/pages/common/utils/index.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { transfromAndCutImg } from 'public/src/services/resource/index'

import { register } from 'swiper/element'
import { Pagination, Navigation } from 'swiper/modules'

// 业务监控
import Monitor from 'public/src/pages/common/business-monitor/store'

typeof window !== 'undefined' && register()

export default {
  name: 'PageIndex',
  provide() {
    return {
      userInfo: this.userInfo,
      apolloConfig: this.apolloConfig,
      abtInfo: this.abtInfo,
      languageText: this.language,
      commonInfo: {
        SiteUID: this.SiteUID,
        langPath: this.langPath,
        IMG_LINK: this.IMG_LINK,
        GB_cssRight: this.GB_cssRight,
        PUBLIC_CDN: this.PUBLIC_CDN,
        dateLangMap: this.dateLangMap,
      },
      cutImg: this.cutImg
    }
  },
  components: {
    Icon,
    sui_icon_survey_28px,
    sui_icon_notice_28px,
    sui_icon_more_right_12px,
    sui_icon_me_coupon_32px,
    sui_icon_me_points_32px,
    sui_icon_me_wallet_32px,
    sui_icon_me_gift_32px,
    sui_icon_me_unpaid_32px,
    sui_icon_me_processing_32px,
    sui_icon_me_shipped_32px,
    sui_icon_me_review_32px,
    sui_icon_me_returns_32px,
    orderItem,
    commonNotice,
    PrimePannel,
    ExtraPannel,
    ClubBadge,
    UserAssetsBottom,
    UnpaidDialog: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "UnpaidDialog" */ './components/UnpaidDialog.vue'
      )
    ),
    UnpaidDialogV2: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "UnpaidDialog" */ './components/unpaid_dialog_v2/UnpaidDialogV2.vue'
      )
    ),
    RecommendCcc: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "RecommendCcc" */ './components/RecommendCcc.vue'
      )
    ),
    FollowLists,
    ClientOnly,
    NewsPannel
  },
  // 使用到mixins的变量和方法，ssr需要处理
  /**
   * 变量：
   * isMsgActivityAndPromoOnly
   * unReadNum
   * couponOrPointsData
   * showCouponTips
   * showPointsTips
   */
  /**
   * 方法:
   * pointsOrCouponJumpEvt
   */
  mixins: [pointsOrCouponMixins],
  props: {
    context: {
      type: Object
    }
  },
  data() {
    const isSsr = !this.context?.noSsr && Object.keys(this.context).length
    const {
      wishlists,
      supplement,
      primeInfoThreeForOne,
      currentUserSavedInfo,
      apolloConfig,
      abtInfo,
      newsInfo,
      saverInfo: {
        isShowSaver,
        isSaverEnable,
        isShowSaverIcon,
        saverInfoThreeForOne
      },
      orderList,
      unReadNewsAndOrderNum,
      isMsgActivityAndPromoOnly,
      unreadTicketNum,
      expireGameMoney,
      newArrivalRes,
      followData,
      languageText,
      userRisk = {},
      reviewEntryAbtResult,
      userInfo
    } = isSsr ? this.context : window.gbUserIndexData

    const {
      IS_RW,
      currency,
      PUBLIC_CDN,
      langPath,
      SiteUID,
      lang,
      IMG_LINK,
      dateLangMap,
      GB_cssRight
    } = isSsr ? this.context : window.gbCommonInfo

    const {
      UNREVIEW_TIME,
      UNREVIEW_NOT_FIRST_SHOW,
      USER_CENTER_UNPAID_ORDER_CONFIG
    } = isSsr ? this.context?.apolloConfig : window.gbCommonInfo

    return {
      /**
       * state in client render
       */
      ordersNum: {},
      isStudent: false,
      couponNum: 0,
      totalPoint: 0,
      isShowAssets: false,
      recentViews: [],
      balances: [],
      cccNoticeContent: '',
      showRiskDialog: false,
      preferenceShow: '',
      preferencePoints: 0,
      unReviewList: [], // 待评论列表
      first_comment_flag: '', //后端返回abt值
      unPaidOrderList: [], // 待支付订单
      countdownEnd: [],
      dialogOrderItem: undefined,
      unpaidOrderType: '',

      /**
       * state in server render
       */
      abtInfo,
      userInfo,
      IS_RW,
      langPath,
      PUBLIC_CDN,
      SiteUID,
      lang,
      currency,
      IMG_LINK,
      GB_cssRight,
      dateLangMap,
      language: languageText,
      reviewEntryAbtResult,
      isRisk: userRisk.isRisk,

      // apollo配置
      apolloConfig,
      UNREVIEW_TIME,
      UNREVIEW_NOT_FIRST_SHOW,
      USER_CENTER_UNPAID_ORDER_CONFIG,

      nickname: userInfo?.nickname || userInfo?.email || '',
      supplement,
      wishlists,
      orderList,

      // 用户资产下方模块数据
      isUserAssetsBottomDataReady: false,
      expireGameMoney,

      // club会员模块数据
      currentUserSavedInfo,
      primeInfoThreeForOne,
      primeProductInfoList: primeInfoThreeForOne?.product_info_list || [],
      primePaidDetail: primeInfoThreeForOne?.paid_detail || {},

      // 超省卡模块数据
      saverInfoThreeForOne, // 超省卡详情
      isSaverEnable, // 超省卡是否开启abt
      isShowSaver, // 超省卡是否展示
      isShowSaverIcon, // 超省卡icon是否展示

      // news页面数据
      newsInfo,
      // 未读消息和订单数量
      unReadNum: unReadNewsAndOrderNum || 0,
      isMsgActivityAndPromoOnly,
      ticketNum: unreadTicketNum || 0,

      // 店铺关注
      newArrivalRes,
      followData,
    }
  },
  computed: {
    resourceSDK () {
      const { RESOURCE_SDK = {} } = this.context || {}
      return RESOURCE_SDK
    },
    // 展示挽留弹框新样式
    isShowNewUnpaidDialog() {
      return ['A', 'B'].includes(this.abtInfo?.MeOrderUnpaid?.p?.PopType)
    },
    // 展示突出优惠力度楼层
    showPreferentialFloor() {
      if (
        ['fr', 'pl', 'de'].includes(this.SiteUID) &&
        this.abtInfo?.MeOrderUnpaid?.p?.compliance_pageme === 'A'
      ) {
        return false
      }
      return this.abtInfo?.MeOrderUnpaid?.p?.FloorType === 'On'
    },
    isShowRecommend() {
      // 是否展示推荐组件
      return this.abtInfo?.pcmeRecommend?.param?.pcmeRecommend == 'on' || false
    },
    isPrime() {
      // 生效中的付费用户
      return (
        !this.IS_RW && this.primePaidDetail?.product_info?.front_status === 1
      )
    },
    isShowPrime() {
      // 生效中付费会员 或者 abt开启&当前站点有可用产品包
      return Boolean(
        !this.IS_RW &&
          (this.isPrime ||
            (this.abtInfo?.sheinclubme?.p === 'exposure_me_sheinclub' &&
              this.primeProductInfoList.length))
      )
    },
    services() {
      return [
        {
          id: 'message',
          title:
            this.language.SHEIN_KEY_PC_16007 &&
            this.language.SHEIN_KEY_PC_16007.toLowerCase(),
          icon: 'sui_icon_notice_28px',
          extra: this.unReadNum,
          link: '/user/message',
          isMsgActivityAndPromoOnly: this.isMsgActivityAndPromoOnly,
          daEventExpose: '1-22-2-6',
          daEventUnread:
            this.unReadNum || this.isMsgActivityAndPromoOnly ? 1 : 0
        },
        {
          id: 'records',
          title: this.language.SHEIN_KEY_PC_15144,
          icon: 'sui_icon_survey_28px',
          extra: this.ticketNum,
          link: '/user/tickets',
          isMsgActivityAndPromoOnly: false,
          daEventExpose: '',
          daEventUnread: ''
        }
      ]
    },
    ordersTab() {
      const DEFAULT_TABS = [
        {
          id: 'unpaid',
          icon: 'sui_icon_me_unpaid_32px',
          title: this.language.SHEIN_KEY_PC_15233,
          extra: this.ordersNum.unpaid,
          link: '/user/orders/list?status_type=1'
        },
        {
          id: 'processing',
          icon: 'sui_icon_me_processing_32px',
          title: this.language.SHEIN_KEY_PC_15031,
          extra: this.ordersNum.processing,
          link: '/user/orders/list?status_type=2'
        },
        {
          id: 'shipped',
          icon: 'sui_icon_me_shipped_32px',
          title: this.language.SHEIN_KEY_PC_15026,
          extra: this.ordersNum.shipped,
          link: '/user/orders/list?status_type=3'
        },
        {
          id: 'review',
          icon: 'sui_icon_me_review_32px',
          title: this.language.SHEIN_KEY_PC_14850,
          extra: this.ordersNum.review,
          link: '/user/review_center'
        },
        {
          id: 'returns',
          icon: 'sui_icon_me_returns_32px',
          title: this.language.SHEIN_KEY_PC_15773,
          extra: this.ordersNum.returns,
          link: '/user/orders/list?status_type=4'
        }
      ]
      // 是否增加评论中心入口
      const isOpenReviewEntry = this.reviewEntryAbtResult === 'type=A'
      return DEFAULT_TABS.filter(
        ({ id }) => id !== (!isOpenReviewEntry ? 'review' : '')
      )
    },
    assets() {
      const arr = [
        {
          id: 'coupon',
          title: this.language.SHEIN_KEY_PC_14930,
          extra: this.isShowAssets
            ? +this.couponNum || 'sui_icon_me_coupon_32px'
            : '***',
          link: '/user/coupon',
          showIcon: this.isShowAssets && !+this.couponNum
        },
        {
          id: 'points',
          title: this.language.SHEIN_KEY_PC_15043,
          extra: this.isShowAssets
            ? +this.totalPoint || 'sui_icon_me_points_32px'
            : '***',
          link: '/user/points',
          showIcon: this.isShowAssets && !+this.totalPoint
        },
        {
          id: 'wallet',
          title: this.language.SHEIN_KEY_PC_15391,
          extra: this.isShowAssets
            ? this.wallets || 'sui_icon_me_wallet_32px'
            : '***',
          link: '/user/wallet',
          showIcon: this.isShowAssets && !this.wallets
        },
        {
          id: 'giftCard',
          title: this.language.SHEIN_KEY_PC_15570,
          extra: 'sui_icon_me_gift_32px',
          link: '/user/gift_card',
          showIcon: true
        }
      ]
      return arr
    },
    wallets() {
      let wallet = 0
      if (this.balances.length > 0) {
        const { currency_code, amount_with_sep, amount } =
          this.balances.find(
            balance => balance.currency_code === this.currency
          ) || {}
        wallet =
          currency_code && amount > 0
            ? `${amount_with_sep || 0}<sub>${currency_code}</sub>`
            : 0
      }
      return wallet
    },
    showCccNotice() {
      let result = !this.supplement && !!this.cccNoticeContent
      if (result) {
        // 公告优化 产品新加的埋点 担心之前的埋点没有被使用了 保留两份
        daEventCenter.triggerNotice({
          daId: '1-22-2-7'
        })
        daEventCenter.triggerNotice({
          daId: '1-22-2-16'
        })
      }
      return result
    },
    showPreference() {
      return !this.supplement && !this.cccNoticeContent && this.preferenceShow
    },
    newUnpaidOrders() {
      // 未支付订单过滤
      const newUnpaidOrders = []
      if (this.unpaidOrderType && this.unpaidOrderType != 'None') {
        this.unPaidOrderList.forEach(item => {
          const { expire_countdown } = item
          const endTime = Number(expire_countdown)
          const nowTime = Date.parse(new Date()) / 1000
          if (nowTime < endTime && !this.countdownEnd.includes(item.billno)) {
            newUnpaidOrders.push(item)
          }
        })
      }
      return newUnpaidOrders
    }
  },
  unmounted() {
    //退出页面
    sa('send', 'pageLeave', { end_time: new Date().getTime() })
  },
  async created() {
    this.pageInit()
    this.waitUserAssetsBottomDataReady()
  },
  mounted() {
    this.initInClient()
    document.body.style.background = '#f7f8fa'
    this.initRecentView()
    // 清除停服期间部分被301重定向的缓存链接
    this.clear301cache()
    //如果是爆破黑名单用户或者泄露名单用户
    window.userIndexEventCenter = window.userIndexEventCenter || mitt()
    // 曝光埋点
    this.initEventExposeEvt()
    // 获取订单信息
    this.getOrderEvt()

    Monitor.metricPageSuccess({ 
      page: 'user-index',
      status: '1',
    })
  },
  emits: ['riskDialog'],
  methods: {
    ...mapMutations(['updateDialogName']),
    template,
    pageInit() {
      this.initWishlist(this.wishlists)
    },
    initInClient() {
      //进入页面 埋点上报
      window.SaPageInfo = {
        page_id: 115,
        page_name: 'page_me',
        start_time: new Date().getTime(),
        page_param: {
          vip_level: null
        }
      }

      daEventCenter.addSubscriber({ modulecode: '1-22-2' })
      window.daEventExpose = daEventCenter.getExposeInstance()
      window.PagemeEvent = mitt()
      // 初始化wishlist-swiper
      nextTick(() => {
        const swiperEl = this.$refs.wishlistSwiperRef
        if (!swiperEl) return

        const options = {
          injectStylesUrls: [
            `${this.PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`,
            `${this.PUBLIC_CDN}/she_dist/libs/swiper/modules/pagination-element.min.css`
          ],
          injectStyles: [
            `
            .swiper-pagination {
              --swiper-pagination-bottom: 2px;
            }
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #ccc;
            }
            .swiper-pagination-bullet-active {
              background: #222;
            }
            `
          ],
          modules: [Navigation, Pagination],
          navigation: {
            enabled: this.wishlists.length > 1,
            prevEl: this.$refs.wishlistPrevRef,
            nextEl: this.$refs.wishlistNextRef
          },
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 5,
          uniqueNavElements: false,
          observer: true
        }
        Object.assign(swiperEl, options)
        swiperEl.initialize()
      })

      InitFtoken(() => {
        this.checkRisk()
      }, true)

      this.getAssetsState()
      this.fetchUnpaidOrderInfo()
      this.getRelativeInfo()
      this.judgeShowCccNotice()
      this.getPreferenceNotice()
    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.resourceSDK

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    // 弹窗展示逻辑
    showDialog() {
      // 埋点上报
      if (this.newUnpaidOrders.length) {
        daEventCenter.triggerNotice({
          daId: '1-22-2-1009',
          extraData: {
            scenes: 'unpaidOrderMe',
            order_id_list: this.newUnpaidOrders
              .map(item => item.billno)
              .join('`')
          }
        })
      }
      let flag = false
      if (this.unpaidOrderType != 'TypeB') flag = true
      if (this.newUnpaidOrders.length == 0) flag = true
      let [dialogOrderItem, cacheKey] = [
        null,
        `unpaid_dialog_cache_${this.SiteUID}_${this.userInfo?.member_id}`
      ]
      let cache = getLocalStorage(cacheKey) || {}
      let billnos = cache?.billnos || []
      // 第二天重置次数
      // 当天结束时间
      const dayEndTime =
        new Date(
          new Date(
            this.USER_CENTER_UNPAID_ORDER_CONFIG.time
          ).toLocaleDateString()
        ).getTime() +
        24 * 60 * 60 * 1000 -
        1
      if (Date.now() > dayEndTime) {
        cache.count = 0
      }
      // 次数限制
      if (
        !this.USER_CENTER_UNPAID_ORDER_CONFIG.times ||
        cache?.count >= this.USER_CENTER_UNPAID_ORDER_CONFIG.times
      )
        flag = true
      // 频率限制
      if (
        !this.USER_CENTER_UNPAID_ORDER_CONFIG.intervel ||
        cache?.time >
          Date.now() - this.USER_CENTER_UNPAID_ORDER_CONFIG.intervel * 60 * 1000
      )
        flag = true
      // 获取订单数据
      this.newUnpaidOrders.forEach(item => {
        if (
          !billnos.includes(item.billno) && // 是否弹出过
          !dialogOrderItem // 取最近的第一个
        ) {
          billnos.push(item.billno)
          dialogOrderItem = item
        }
      })
      // 过滤历史数据
      billnos = billnos.filter(_ =>
        this.newUnpaidOrders.some(val => val.billno == _)
      )
      if (!dialogOrderItem || flag) {
        this.updateDialogName('next-renew')
        return
      }
      this.dialogOrderItem = dialogOrderItem
      // 设置缓存
      setLocalStorage({
        key: cacheKey,
        value: {
          count: cache?.count ? cache.count + 1 : 1,
          time: Date.now(),
          billnos
        }
      })
    },
    badgeNum_filter(num) {
      if (this.isRisk || !num) {
        return 0
      }
      return Number(num) > 99 ? '99+' : num
    },
    clear301cache() {
      try {
        let _urlArr = [
          location.origin,
          location.origin + '/cart',
          location.origin + '/checkout'
        ]
        if (document.referrer) _urlArr.push(document.referrer)
        if (getQueryString({ key: 'countdown' }) > 0) {
          _urlArr.forEach(url => {
            fetch(url, {
              method: 'GET',
              headers: { pragma: 'no-cache', 'cache-control': 'no-cache' }
            }).then(() => console.log(url + ' REQ SUCCESS'))
          })
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    transformImg: _transformImg,
    htmlDecode: ({ text, isHtml = true }) => htmlDecode({ text, isHtml }),
    getQueryString,
    setCookie,
    getCookie,
    getGoodsUrl: getGoodsUrl,
    getAssetsState() {
      const state = localStorage.getItem('IS_SHOW_ASSETS')
      this.isShowAssets = state === '1'

      if (this.isRisk) {
        this.isShowAssets = false
        localStorage.setItem('IS_SHOW_ASSETS', '0')
      }
    },
    toggleShowAssets() {
      this.isShowAssets = !this.isShowAssets
      localStorage.setItem('IS_SHOW_ASSETS', this.isShowAssets ? '1' : '0')
    },
    initEventExposeEvt() {
      nextTick(() => {
        window.daEventExpose.subscribe({
          keycode: 'userIndexExpose`1-22-2-6'
        })
      })
    },
    getUnReadTicketNum() {
      return new Promise((resolve) => {
        schttp({
          url: '/api/robot/common/unReadCount/query',
          params: {
            types: 'tk'
          }
        }).then((res) => {
          const ticketNum = res?.info?.tk_unread_count || 0
          resolve(ticketNum > 0 ? ticketNum : 0)
        })
      })
    },
    async waitUserAssetsBottomDataReady() {
      if (typeof window === 'undefined') return
      window.appEventCenter = window.appEventCenter || mitt()
      await new Promise(resolve => {
        window.appEventCenter?.on('initCouponOrPointsNoticeDone', () => {
          resolve()
          window.appEventCenter.off?.('initCouponOrPointsNoticeDone')
        })
      })
      this.isUserAssetsBottomDataReady = true
    },
    toLink(link, id, extra) {
      //如果是爆破黑名单
      if (!['giftCard'].includes(id) && this.isRisk) {
        window.userIndexEventCenter.emit('riskDialog', true)
        return
      }

      if (link == '/user/message') {
        daEventCenter.triggerNotice({
          daId: '1-22-2-3',
          extraData: {
            unread_amount: this.unReadNum || 0,
            category: 'My Account'
          }
        })
      }
      if (id == 'giftCard') {
        daEventCenter.triggerNotice({
          daId: '1-22-2-1010',
          extraData: {}
        })
      }
      if (id == 'review') {
        daEventCenter.triggerNotice({
          daId: '1-22-2-5',
          extraData: {
            extra_amount: extra || 0
          }
        })
      }
      let activity_param = {}
      const { currency_code, amount_with_sep = 0 } = this.balances.find(balance => balance.currency_code === this.currency) || {}
      switch(id) {
        case 'coupon':
          activity_param = {
            coupon_num: this.couponNum || 0
          }
          break
        case 'points':
          activity_param = {
            points_num: this.totalPoint || 0
          }
          break
        case 'wallet':
          activity_param = {
            wallet_num: amount_with_sep != 0 ? `${currency_code}_${amount_with_sep}` : null
          }
          break
        default:
          break
      }
      analysisEvent(id, activity_param)
      nextTick(() => {
        this.pointsOrCouponJumpEvt(id, 1)
      })
      window.location.href = link
    },
    initRecentView() {
      const recentViews = localStorage.getItem('pc_recent_views')
      if (recentViews) {
        this.recentViews = JSON.parse(recentViews)
        this.recentViews = [
          [...this.recentViews.slice(0, 3)],
          [...this.recentViews.slice(3, 6)],
          [...this.recentViews.slice(6, 9)]
        ].filter(view => view.length)
        nextTick(() => {
          const swiperEl = this.$refs.recentSwiperRef
          if (!swiperEl) return

          const options = {
            injectStylesUrls: [
              `${this.PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`,
              `${this.PUBLIC_CDN}/she_dist/libs/swiper/modules/pagination-element.min.css`
            ],
            injectStyles: [
              `
              .swiper-pagination {
                --swiper-pagination-bottom: 2px;
              }
              .swiper-pagination-bullet {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #ccc;
              }
              .swiper-pagination-bullet-active {
                background: #222;
              }
              `
            ],
            modules: [Navigation, Pagination],
            navigation: {
              enabled: this.recentViews.length > 1,
              prevEl: this.$refs.recentPrevRef,
              nextEl: this.$refs.recentNextRef
            },
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 5,
            uniqueNavElements: false,
            observer: true
          }
          Object.assign(swiperEl, options)
          swiperEl.initialize()
        })
      }
    },
    initWishlist(wishlists) {
      this.wishlists = [
        [...wishlists.slice(0, 3)],
        [...wishlists.slice(3, 6)],
        [...wishlists.slice(6, 9)]
      ].filter(wish => wish.length)
    },
    async getRelativeInfo() {
      const { balances, couponNum, isStudent, ordersNum, totalPoint } =
        await this.getAccountInfo()

      // 学生标识展示由abt控制
      if (this.abtInfo?.HideStudent?.param === 'type=B') {
        this.isStudent = isStudent
      }
      this.totalPoint = totalPoint
      this.balances = balances
      this.couponNum = couponNum
      this.ordersNum = {
        ...this.ordersNum,
        ...ordersNum
      }

      const orderstatus_num = `${this.language.SHEIN_KEY_PC_15233}_${
        this.ordersNum?.unpaid || 0
      },${this.language.SHEIN_KEY_PC_15031}_${
        this.ordersNum?.processing || 0
      },${this.language.SHEIN_KEY_PC_15026}_${this.ordersNum?.shipped || 0},${
        this.language.SHEIN_KEY_PC_14850
      }_${this.ordersNum?.review || 0},${this.language.SHEIN_KEY_PC_15773}_${
        this.ordersNum?.returns || 0
      }`

      // 订单状态曝光埋点
      daEventCenter.triggerNotice({
        daId: '1-22-2-18',
        extraData: {
          orderstatus_num: orderstatus_num
        }
      })
      this.exposeMyAssets()
    },
    getAccountInfo() {
      return new Promise(resolve => {
        schttp({
          method: 'POST',
          url: `/api/user/center/getUserInfo/get`
        }).then(res => {
          resolve(res)
        })
      })

    },
    // 获取待支付列表
    async fetchUnpaidOrderInfo() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      this.unpaidOrderType =
        this.abtInfo?.unpaidOrderMe?.param?.unpaidOrderMe || ''
      try {
        if (this.unpaidOrderType != 'None') {
          const data = await schttp({
            url: '/api/orders/base/getUnpaidOrderByStatus/query'
          })
          this.unPaidOrderList = data
          data &&
            this.orderList.forEach(n => {
              const item = data.find(m => m.billno === n.billno)
              if (item) {
                n.total_discount_new = item.total_discount_new
              }
            })
        }
      } catch (error) {
        return []
      }
      this.showDialog()
    },
    async getPreferenceNotice() {
      const preClickPreferenceTime = this.getPreCacheInfoEvt(
        `${this.userInfo.member_id}_preference_time`
      )
      const hadReceive = this.getPreCacheInfoEvt(
        `${this.userInfo.member_id}_receive`
      )
      const actStartTime = new Date('2021-05-01 00:00:00').getTime()
      const actEndTime = new Date('2021-05-31 23:59:59').getTime()
      const currentTime = new Date().getTime()
      const over24Hours = preClickPreferenceTime
        ? currentTime > preClickPreferenceTime + 24 * 3600 * 1000
        : true
      const isActive = currentTime < actEndTime && currentTime > actStartTime
      if (!isLogin()) return
      if (hadReceive == '1') return
      if (!isActive) return
      if (!over24Hours) return
      const resultInfo = await this.getPreferenceInfo()
      const preferInfo = (resultInfo.info && resultInfo.info.result) || {}
      this.preferenceShow =
        resultInfo.code == '0' &&
        preferInfo.is_reward == 1 &&
        preferInfo.reward_type == 1 &&
        !!+preferInfo.reward_value
      if (this.preferenceShow) {
        this.preferencePoints = +preferInfo.reward_value
        daEventCenter.triggerNotice({
          daId: '1-22-2-9'
        })
      }
    },
    getPreCacheInfoEvt(key) {
      return window.localStorage.getItem(key)
    },
    getPreferenceInfo() {
      return new Promise(resolve => {
        schttp({
          url: `/api/user/center/preferReward/get`
        }).then(data => {
          resolve(data || {})
        })
      })
    },
    jumpableNotice() {
      const currentTime = new Date().getTime()
      window.localStorage.setItem(
        `${this.userInfo.member_id}_preference_time`,
        currentTime
      )
      daEventCenter.triggerNotice({
        daId: '1-22-2-10'
      })
      window.location.href = `${this.langPath}/user/account_setting`
    },
    toBounus() {
      schttp({
        url: `/api/user/center/setTip/get`
      }).then(() => {
        window.location.href = `${this.langPath}/bonus-point-program-a-371.html`
      })
    },
    getUserCenterNoticeTipEvt() {
      return new Promise(resolve => {
        let params = {
          siteUid: this.SiteUID
        }
        schttp({
          url: `/api/user/center/getUserCenterNoticeTips/get`,
          params: params
        }).then(res => {
          if (res && res.code == '1000' && res.data) {
            resolve(res.data || {})
          } else {
            resolve({})
          }
        })
      })
    },
    closeCccNotice() {
      this.cccNoticeContent = ''
      setLocalStorage({
        key: 'hideCccNotice',
        value: '1',
        expire: 24 * 60 * 60 * 1000
      })
      daEventCenter.triggerNotice({
        daId: '1-22-2-8'
      })
      // 公告优化 产品新加的埋点 担心之前的埋点没有被使用了 保留两份
      daEventCenter.triggerNotice({
        daId: '1-22-2-17'
      })
    },
    exposeCccDetail() {
      daEventCenter.triggerNotice({
        daId: '1-22-2-15'
      })
    },
    async judgeShowCccNotice() {
      const hideCccNotice = getLocalStorage('hideCccNotice')
      if (hideCccNotice) return
      const result = await this.getUserCenterNoticeTipEvt()
      this.cccNoticeContent = result?.content || ''
    },
    checkRisk() {
      if (this.isRisk) {
        schttp({
          method: 'POST',
          url: `/api/user/center/checkRisk/create`,
          data: {
            ftoken: gbCommonInfo.ftoken,
            scene: 'view_privacy'
          }
        })
          .then(res => {
            if (res?.code == 0 && res?.info?.extend_info) {
              window.userIndexEventCenter.on('riskDialog', isShow => {
                this.$refs.riskDialog.dialogUpdateInfo({
                  scene: 'view_privacy',
                  info: res.info
                })

                this.showRiskDialog = isShow
              })
            } else {
              this.isRisk = false
            }
          })
          .catch(() => {
            this.isRisk = false
          })
      }
    },
    loginModalSucCb() {
      location.reload()
    },
    // 获取订单内容
    async getOrderEvt() {
      const unReviewInfoResult = await this.fetchUnReviewInfo()
      await this.handleUnReviewInfo(unReviewInfoResult)
    },
    // 获取待评论列表
    fetchUnReviewInfo() {
      return schttp({
        method: 'POST',
        url: `/api/user/review_center/infoByStatus/query`,
        data: {
          page: 1,
          limit: 1,
          query_type: 1
        }
      })
    },
    // 获取订单可获得的评论奖励
    fetchUnReviewRewards(billno_list) {
      return schttp({
        method: 'POST',
        url: `/api/user/review_center/queryBillnoReviewRewards/query`,
        data: {
          billno_list: billno_list
        }
      })
    },
    // 处理待评论列表
    async handleUnReviewInfo(unReviewInfoResult) {
      if (
        unReviewInfoResult?.code == '0' &&
        unReviewInfoResult?.info?.data?.length
      ) {
        const timeNow = new Date().getTime()
        const maxTime = this.UNREVIEW_TIME * 24 * 60 * 60 * 1000
        // test
        // const maxTime = 18 * 24 * 60 * 60 * 1000
        const list = unReviewInfoResult.info.data
        const filterList = list.filter(
          el => timeNow - el.order_time * 1000 < maxTime
        )
        const billnoList = []
        const imgs = []
        filterList.forEach(el => {
          billnoList.push(el.billno)
          const imgItem = el?.goods_info.find(item => item.can_comment == 1)
          imgs.push(imgItem.goods_thumb)
        })
        if (!billnoList.length) {
          return
        }
        const contentListObj = await this.handleUnReviewRewards(billnoList)
        const { contentList, isFirstList } = contentListObj
        if (!contentList.length) return
        contentList.map((_, index) => {
          this.unReviewList.push({
            img: imgs[index],
            content: contentList[index],
            billno: billnoList[index],
            isFirst: isFirstList[index]
          })
        })
        // 曝光埋点上报
        daEventCenter.triggerNotice({
          daId: '1-22-2-1003',
          extraData: {
            order_id: billnoList[0],
            floor_type: 'waitingReview',
            is_first_review: billnoList?.[0]?.isFirst ? 1 : 0,
            discount_status: '-'
          }
        })
        daEventCenter.triggerNotice({
          daId: '1-22-2-1005',
          extraData: {
            scenes: this.first_comment_flag ? 'firstReview' : 'review',
            order_id_list: billnoList.join('`')
          }
        })
      }
    },
    // 处理待评论奖励
    async handleUnReviewRewards(billnoList) {
      const result = await this.fetchUnReviewRewards(billnoList)
      if (result?.code == 0) {
        const contentList = []
        const isFirstList = []
        const info = result.info
        info?.point_rewards?.forEach(el => {
          let contentItem = this.template(
            this.getStrongOrange(el.point),
            this.language.SHEIN_KEY_PC_27375
          )
          contentList.push(contentItem)
          isFirstList.push(false)
        })
        //后端返回abt值
        this.first_comment_flag = info.first_comment_flag || 0
        // 存在待评论优惠，则第一个积分改为券展示
        if (contentList.length > 0 && info.coupon_reward) {
          const { rules = [], discount_type } = info.coupon_reward
          if (!rules.length) return
          let contentText = ''
          const ruleItem = rules[rules.length - 1]
          if ([1, 2, 5].includes(discount_type)) {
            // 优惠券类型
            contentText = discountPercentNum({
              val: ruleItem.value,
              percent: true
            })
          } else {
            contentText = ruleItem.value_amount ?? ''
          }
          const contentItem = this.template(
            this.getStrongOrange(contentText),
            this.language.SHEIN_KEY_PC_27374
          )
          contentList.splice(0, 1, contentItem)
          isFirstList.splice(0, 1, true)
        }
        // 非首单奖励全部过滤掉
        if (!this.UNREVIEW_NOT_FIRST_SHOW) {
          if (isFirstList[0]) {
            return {
              contentList: [contentList[0]],
              isFirstList: [isFirstList[0]]
            }
          } else {
            return { contentList: [], isFirstList: [] }
          }
        }
        return { contentList, isFirstList }
      }
    },
    getStrongOrange(text) {
      return `<strong class="strong-orange">${text}</strong>`
    },
    goToReviewDetail(item) {
      window.location.href = `${this.langPath}/user/orders/order_review?billno=${item.billno}&review_page_location=individual`
      daEventCenter.triggerNotice({
        daId: '1-22-2-1004',
        extraData: {
          order_id: item?.billno,
          floor_type: 'waitingReview',
          is_first_review: item.isFirst ? 1 : 0,
          discount_status: '-'
        }
      })
    },
    handleSetLevel(level) {
      window.SaPageInfo.page_param.vip_level = level
      sa('set', 'setPageData', SaPageInfo)
      sa('send', 'pageEnter', { start_time: new Date().getTime() })
    },
    exposeMyAssets() {
      const { currency_code, amount_with_sep = 0 } = this.balances.find(balance => balance.currency_code === this.currency) || {}
      daEventCenter.triggerNotice({
        daId: '1-22-2-33',
        extraData: {
          my_assets_list: [
            {
              'item_type': 'my_coupons',
              'coupon_num': this.couponNum || 0
            },
            {
              'item_type': 'shein_points',
              'points_num': this.totalPoint || 0
            },
            {
              'item_type': 'my_wallet',
              'wallet_num': amount_with_sep != 0 ? `${currency_code}_${amount_with_sep}` : null
            },
            {
              'item_type': 'gift_card'
            }
          ]
        }
      })
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.user-remind {
  background: rgba(217, 234, 255, 1);
  margin-bottom: 10px;
  padding: 20px 0 20px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  & when (@IS_RW) {
    background: #ffefed;
  }
  .user-remind-left {
    color: #666666;
    font-size: 18px;
  }
  .content {
    font-size: 14px;
    color: rgba(34, 34, 34, 1);
    flex: 1;
    margin: 0 10px;
  }
  .user-remind-right {
    color: #999;
    font-size: 14px;
    .margin-r(16px);
    .margin-l(24px);
  }
}
.ccc-remind {
  margin-bottom: 10px;
}
.user-risk-dialog {
  .sui-dialog__wrapper {
    .sui-dialog__body {
      padding: 0;
      margin: 0;
    }
  }
}

.preference-remind {
  .padding-r(0);
  margin-bottom: 10px;
  .icon-jumpable {
    .margin-r(16px);
    .margin-l(24px);
    color: #999;
    cursor: pointer;
  }
}
.c-my-account-new {
  display: flex;
  justify-content: space-between;
  .sui_icon_more_right_12px {
    font-weight: normal;
  }
  .header-title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }
  .more {
    color: #666;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: #222;
    }
  }
  .oops {
    text-align: center;
    .icon-Rectangle {
      font-size: 70px;
    }
    .empty {
      width: 70px;
      height: auto;
    }
    .desc {
      margin-top: 10px;
      color: #999;
      font-size: 12px;
    }
  }
  .badge {
    position: absolute;
    top: 0;
    .left(0);
    text-align: center;
    background: @sui_color_guide;
    color: #fff;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    border-radius: 8px;
    padding: 0 4px;
    background-clip: padding-box;
    vertical-align: middle;
    font-style: normal;
    em {
      transform: scale(0.9);
    }
  }
  .content {
    .margin-r(10px);
    flex: 1;
    .announce {
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px 10px;
      .padding-r(40px);
      margin-bottom: 10px;
      background: #fff;
      .desc {
        font-size: 14px;
        line-height: 1.12;
        word-break: break-all;
      }
      .icon-close,
      .icon-biaoqianclose-px {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .right(16px);
        margin: 0;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .my-assets {
      background: #fff;
      padding: 20px;
      & when (@IS_RW) {
        border-radius: 4px;
      }
      .user-info {
        .flexbox();
        .space-between();
        .align-center();
        width: 100%;
        cursor: pointer;
        max-width: 656px;
        .nickname-content {
          flex: 1;
          overflow: hidden;
          .avatar {
            display: inline-block;
            width: 84px;
            height: 84px;
            border-radius: 50%;
          }
          .nickname {
            display: inline-flex;
            font-size: 18px;
            color: #222;
            font-weight: bold;
            width: 100%;
            align-items: center;
            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: inline-block;
            }
            .student {
              .margin-l(14px);
              width: 14px;
              height: auto;
            }
          }
          .vip {
            .margin-l(8px);
            text-align: center;
            display: inline-flex;
            align-items: center;
          }
        }
        .nickname-content-right {
          color: #666666;
          font-weight: bold;
        }
      }
      .assets {
        // padding: 4px 30px 44px;
        .title {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
          &.eye-title {
            margin-top: 10px;
          }
        }
        .tabs {
          display: flex;
          align-items: stretch;
          margin-top: 18px;
          .tab-item {
            flex: 1;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 4px;
            text-align: center;
            cursor: pointer;
            &:first-child {
              .padding-l(0);
            }
            &:last-child {
              .padding-r(0);
            }
            .tab-extra,
            .tab-title {
              display: block;
            }
            .tab-extra {
              height: 36px;
              line-height: 36px;
              font-size: 16px;
              font-weight: bold;
              & when (@IS_RW) {
                font-family: Adieu;
              }
              sub {
                bottom: 0;
              }
            }
            .icon-gift_40px {
              display: inline-block;
              font-size: 32px;
              font-weight: normal;
              & when (@IS_RW) {
                font-size: 28px;
              }
            }
            .tab-title {
              margin-top: 10px;
              color: #666;
              font-size: 14px;
              text-align: center;
            }
            &:hover {
              .tab-title {
                color: #222;
              }
            }
            &.is-show:hover {
              .tab-extra {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
    .my-orders {
      margin-top: 10px;
      padding: 10px 10px 20px;
      background: #fff;
      & when (@IS_RW) {
        border-radius: 4px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 10px;
      }
      .orders-tab {
        display: flex;
        padding: 20px;
        .tab-item {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          .tab-icon {
            display: inline-block;
            font-size: 32px;
          }
          .tab-title {
            margin: 10px 0;
            color: #666;
            font-size: 14px;
            text-align: center;
          }
          .tab-top {
            position: relative;
            .tab-badge {
              top: -6px;
              .left(100%);
            }
          }
          &:hover {
            .tab-icon {
              transform: scale(1.2);
            }
            .tab-title {
              color: #222;
            }
          }
        }
      }
      .load-ctn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 304px;
      }
      .order-list {
        margin: 0 10px;
        padding: 20px;
        background: #fafbfc;
        .list-item {
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px dashed #e5e5e5;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
          }
          .list-h {
            color: #666;
            font-size: 12px;
          }
          .list-c {
            display: table;
            margin-top: 15px;
            width: 100%;
          }
          .col {
            padding: 15px 0;
            display: table-cell;
            vertical-align: middle;
            font-size: 14px;
            text-align: center;
            width: 20%;
            box-sizing: border-box;

            &.align-left {
              text-align: left /* rtl: right */;
            }
          }
          .item-pic {
            height: 100%;
            padding-top: 7px;
            padding-bottom: 6px;
            display: table-cell;
            vertical-align: middle;
            position: relative;
            &__swiper-container {
              display: flex;
              flex-wrap: nowrap;
              width: 104px;
              height: 100%;
              overflow: hidden;
            }
            &__swiper-slide {
              flex-shrink: 0;
              width: 100%;
              img {
                width: 45px;
                height: 60px;
              }
            }
            &__button-prev {
              .left(17%);
            }
            &__button-next {
              .right(17%);
            }
            &__button-prev,
            &__button-next {
              position: absolute;
              height: auto;
              top: 50%;
              transform: translateY(-50%);
              z-index: @zindex-hover;
            }
            &__button-prev,
            &__button-next {
              .sui-icon-common__wrap {
                transform: rotateZ(0deg) /* rtl:rotateZ(180deg) */;
              }
            }
            .swiper-button-disabled {
              opacity: 0.35;
            }
          }
          .order-status-wrap {
            display: inline-block;
            .order-status {
              font-weight: normal;
              text-transform: capitalize;
            }
            .c-icon-tip-spec {
              display: inline;
              padding: 5px 0;
              i {
                font-size: 14px;
                color: @sui_color_gray_dark1;
              }
              .deliver-bottom {
                .margin-l(-8px);
              }
              .show_confirmDeliver_tips {
                position: absolute;
                background: #fff;
                z-index: 40; /* stylelint-disable-line declaration-property-value-blacklist */
                width: 300px;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 6px;
                top: 29px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                &:after {
                  position: absolute;
                  left: 50%;
                  z-index: 50; /* stylelint-disable-line declaration-property-value-blacklist */
                  top: -6px;
                  width: 12px;
                  height: 12px;
                  content: '';
                  background: #fff;
                  .border-l(1px,#ccc);
                  border-top: 1px solid #ccc;
                  transform: translateX(-50%) rotate(45deg);
                }
                .red {
                  color: #e64545;
                  font-weight: bold;
                }
              }
            }
          }
          .detail-btn {
            color: @sui_color_link;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
      }
      .oops {
        padding: 40px;
        background: #fafbfc;
      }
    }
  }
  .aside {
    flex: 0 0 auto;
    width: 286px;
    .service {
      padding: 20px 20px 44px;
      height: 180px;
      background: #fff;
      & when (@IS_RW) {
        border-radius: 4px;
      }
      .tabs {
        display: flex;
        margin-top: 40px;
        .tab-item {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 10px /* rtl:unset */;
            margin-right: unset /* rtl:10px */;
          }
          .tab-top {
            position: relative;
            .tab-badge {
              top: -6px;
              .left(100%);
            }
            .msg-activity-only {
              position: absolute;
              top: -1px;
              .right(-4px);
              padding: 0;
              min-width: 8px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border: 0;
              & when (@IS_SH) {
                background: #ff411c;
              }
              & when (@IS_RW) {
                background: @color_brand;
              }
            }
          }
          .tab-title {
            margin-top: 10px;
            color: #666;
            font-size: 14px;
            text-align: center;
            text-transform: capitalize;
          }
          &:hover {
            .tab-title {
              color: #222;
            }
          }
        }
      }
    }
    .goods {
      & when (@IS_RW) {
        border-radius: 4px;
      }
    }

    .goods {
      margin-top: 10px;
      background: #fff;
      & when (@IS_RW) {
        padding: 20px 10px;
      }
      &.pagination {
        padding-bottom: 4px;
        & when (@IS_RW) {
          padding-bottom: 44px;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        & when (@IS_RW) {
          padding: 0;
          margin: 5px 10px 20px;
        }
      }
      .lists-wrap {
        padding: 0 10px;
        & when (@IS_RW) {
          padding: 0px;
        }
      }
      .lists {
        position: relative;
        cursor: pointer;
        padding: 0 26px;
        & when (@IS_RW) {
          padding: 0 26px;
        }
        &__swiper-container {
          display: flex;
          flex-wrap: nowrap;
          overflow: hidden;
        }
        &__swiper-slide {
          flex-shrink: 0;
          margin-right: 5px;
          width: 68px;
          padding-bottom: 20px;
          img {
            width: 100%;
            height: 90px;
          }
        }
        .swiper-button-disabled {
          opacity: 0.35;
        }
        &__swiper-next,
        &__swiper-prev {
          position: absolute;
          top: calc(50% - 10px);
          display: flex;
          align-items: center;
          display: none;
        }
        &:hover {
          .lists__swiper-next,
          .lists__swiper-prev {
            display: flex;
          }
        }
        &__swiper-next {
          .right(0);
        }
        &__swiper-prev {
          .left(0);
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}
.font-bold {
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
}
.icon-main {
  color: @sui_color_main;
}
.vip-modules {
  display: flex;
  margin-top: 8px;
}
.shein-extra_badge {
  width: 80px;
  height: 16px;
  background: url('/she_dist/images/extra-label-4234d856b8.png') no-repeat
    center;
  background-size: contain;
  margin-left: 8px;
}
.c-my-account-new .content .my-orders .orders-tab {
  padding-bottom: 0;
}
.unreview-order {
  position: relative;
  display: flex;
  margin: 0px 10px 20px;
  padding: 6px 12px 6px 6px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--sui-color-micro-emphasis-bg, #fff8eb);
  cursor: pointer;
  &__logo {
    position: absolute;
    top: -12px;
    right: 160px;
    width: 20px;
    height: 12px;
  }
  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 36px;
    height: 36px;
    margin-right: 6px;
  }
  &__text {
    color: #000;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /deep/ .strong-orange {
      color: #fa6338;
    }
    /deep/ span {
      font-weight: bold;
    }
  }
}
.click_able {
  cursor: pointer;
}
.mt__2 {
  margin-top: -2px;
}
.userIndex__member-risk-dialog {
	.sui-dialog__wrapper {
		.sui-dialog__body {
			padding: 0;
			margin: 0;
		}
	}
}
</style>
